/* eslint max-len: "off", comma-dangle: "off", quote-props: "off" */

import customTranslations from './customTranslationsProxy';

const translations = {
  en: {
    MUSEUM: ' ',
    LANG_FULL: 'English',
    // date format as described in https://chmln.github.io/flatpickr/formatting/
    DATE_FORMAT: 'd.m.Y',

    HIGHLIGHT_TITLE: 'Welcome',
    HIGHLIGHT_DESCRIPTION: ' ',

    NAV_MERCHANDISE: 'Shop',
    NAV_TICKETS: 'Tickets',
    NAV_GUIDED_TOURS: 'Guided Tours',
    NAV_ALL: 'All',
    NAV_BOOKS: 'Books',
    NAV_POSTERS: 'Posters',
    NAV_LOGIN: 'Login',
    NAV_REGISTER: 'Register',
    NAV_SETTINGS: 'Settings',
    NAV_LOGOUT: 'Logout',
    NAV_BACK: 'Go back',
    NAV_BACK_TO: 'Back to',
    NAV_REGISTER_AS_GUEST: 'Billing information',
    NAV_DAY_TICKETS: 'Tickets',
    NAV_MEMCARD: 'Annual Passes',

    BREADCRUMBS_MUSEUM_HOME: 'Home',
    BREADCRUMBS_WEBSHOP: 'webshop',

    GO_HOME: 'Back to shop',

    ALL_PRICES_INCLUDE_VAT: 'all prices include VAT',
    ALL_PRICES_INCLUDE_VAT_SHORT: 'incl. VAT',

    DATE_PICKER_UNAVAILABLE_DAY: 'Day is not available',
    DATE_PICKER_SOLDOUT_DAY: 'Day is sold out',
    TIME_PICKER_SOLDOUT_TIME: 'The time is sold out',

    TICKETS_PAGE_TITLE: 'Tickets',
    TICKETS_TIME: 'Time',
    TICKETS_BUY_PREFIX: 'Buy',
    TICKETS_BUY_SUFFIX: ' ',
    TICKETS_TICKETS: 'Tickets',
    TICKETS_TICKET: 'Ticket',
    TICKETS_FROM: 'from',
    TICKETS_TO: 'to',
    TICKETS_SELECT_A_DATE: 'Please select your desired date',
    TICKETS_ADDED_TO_CART: 'Tickets added to cart',
    TICKETS_TOTAL: 'Total',
    TICKETS_WRONG_FAMILY_TICKET_COUNT: 'Please select a quantity for every ticket component',
    TICKETS_MOBILE_TICKETS: 'Show mobile ticket',
    TICKETS_EXPIRED: 'This event has expired!',
    TICKETS_OVERBOOKING_MSG_PART1: 'You are trying to book ',
    TICKETS_OVERBOOKING_MSG_PART2: 'tickets but there are only',
    TICKETS_OVERBOOKING_MSG_PART3: 'available',
    TICKETS_TIME_UNAVAILABLE: 'Tickets sold out',
    TICKETS_NO_EVENTS: 'No results for this selection',
    TICKETS_NO_EVENTS_MATCHING_FILTERS: 'No results for this selection',

    DAY_TICKETS_PAGE_TITLE: 'Tickets',

    GUIDED_TOURS_PAGE_TITLE: 'Guided Tours',
    GUIDED_TOURS_NO_TOURS: 'No tours for the selected filters',
    GUIDED_TOURS_PRICES_FROM: 'Price from:',

    GUIDED_TOURS_ANY_DATE: 'Date',
    GUIDED_TOURS_TODAY: 'Today',
    GUIDED_TOURS_TOMORROW: 'Tomorrow',

    GUIDED_TOUR_SINGLE_PAGE_TITLE: 'Guided Tours',
    GUIDED_TOUR_SELECT_DATE: 'Select a date',
    GUIDED_TOUR_SELECT_TIME: 'Select a time',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART1: 'This guided tour is only available to groups of at least ',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_SINGULAR: 'person',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_PLURAL: 'person',

    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART1: 'You are trying to book ',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART2: 'tickets but there are only',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART3: 'available',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART1: 'Your are trying to book for',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART2: 'participants but only',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART3: 'are available',
    GUIDED_TOUR_MORE_TICKETS_THAN_TOURS_MSG:
      'The number of tickets and participants should be the same',
    GUIDED_TOUR_LESS_TICKETS_THAN_TOURS_MSG:
      'The number of tickets and participants should be the same',

    GUIDED_TOUR_PARTICIPANTS: 'persons',
    GUIDED_TOUR_TOTAL_PRICE: 'Total',
    GUIDED_TOUR_AVAILABLE_TICKETS_FOR_SELECTED_TIME: 'Available tickets for selected time',
    GUIDED_TOUR_AVAILABLE_PARTICIPANTS_FOR_SELECTED_TIME:
      'Available number of participants for selected time',
    GUIDED_TOUR_BUY_TICKETS_PREFIX: 'Buy',
    GUIDED_TOUR_BUY_TICKETS_SUFFIX: 'tickets',
    GUIDED_TOUR_BUY: 'Buy',
    GUIDED_TOUR_ADDED_TO_CART: 'Guided tour added to cart',
    GUIDED_TOUR_FILTER_LANGUAGE: 'Language filter',
    GUIDED_TOUR_NOT_AVAILABLE: 'This guided tour is currently unavailable',
    GUIDED_TOUR_SOLD_OUT: 'This tour is sold out',
    GUIDED_TOUR_NO_TICKET_AVAILABLE: 'There are no tickets available for this guided tour',
    GUIDED_TOUR_VAT: 'Vat',
    GUIDED_TOUR_QUESTIONS: 'Additional information about the group',
    GUIDED_TOUR_NOT_AVAILABLE_THIS_DAY_BUT_AVAILABLE_ON:
      'The tour is not available for the selected date. The next available date is',
    GUIDED_TOUR_SHOW_ME: 'show me',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_1: 'of',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_2: 'tours selected. Accommodates a maximum of',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_3: 'participants.',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B1: 'guided tours with max.',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B2: 'participants per guided tour',
    GUIDED_TOUR_IS_NOT_BOOKABLE: 'currently not bookable',

    FIELD_IS_REQUIRED: 'Please answer all mandatory fields.',

    AUTH_SALUTATION_LABEL: 'Title',
    AUTH_EMAIL_LABEL: 'Email',
    AUTH_EMAIL_CONFIRM_LABEL: 'Confirm Email',
    AUTH_EMAILS_DO_NOT_MATCH: 'Emails do not match',
    AUTH_PASSWORD_LABEL: 'Password',
    AUTH_PASSWORD_CONFIRM_LABEL: 'Confirm Password',
    AUTH_PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    AUTH_COMPANY_LABEL: 'Company',
    AUTH_PHONE_LABEL: 'Phone',
    AUTH_FIRST_NAME_LABEL: 'First Name',
    AUTH_LAST_NAME_LABEL: 'Last Name',
    AUTH_CITY_LABEL: 'City',
    AUTH_ADDRESS_LABEL: 'Address (Street, Number)',
    AUTH_COUNTRY_LABEL: 'Country',
    AUTH_ZIP_LABEL: 'Zip',
    AUTH_FORM_HAS_ERRORS: 'Please correct the errors in the form',
    AUTH_NAME: 'Name',

    AUTH_BTN_LOGIN: 'Login',
    AUTH_BTN_REGISTER: 'Register',
    AUTH_BTN_RECOVER: 'Recover',
    AUTH_BTN_SAVE: 'Save',

    AUTH_FORGOT_PASSWORD: 'Did you forget your password?',
    AUTH_DONT_HAVE_ACCOUNT_QUESTION: "I don't have an account yet?",
    AUTH_ALREADY_HAVE_ACCOUNT_QUESTION: 'I already have an account?',
    AUTH_TRIED_TO_ACCESS_UNAUTHORIZED_PAGE: 'The page you were trying to access requires login',
    AUTH_SENT_WRONG_CREDENTIALS: 'Email or password are not correct',

    AUTH_FORGOT_INVALID_EMAIL: 'Cannot recover this Email',

    AUTH_LOGGING_IN: 'Sign in',
    AUTH_LOGIN_SUCCESS: 'Login Successful',
    AUTH_LOGIN_FAILED: 'Login Failed',
    AUTH_REQUESTING_RESET: 'Requesting a reset',
    AUTH_REQUEST_RESET_SUCCESS: 'Password reset successful',
    AUTH_REQUEST_RESET_FAIL: 'Password reset failed',
    AUTH_SUCCESS_RESET_TITLE: 'Password reset successful',
    AUTH_SUCCESS_RESET_MESSAGE:
      'You should be receiving an Email shortly with instructions on how to proceed.',
    AUTH_NEW_PASSWORD_LABEL: 'New password',
    AUTH_RECOVER_ACCOUNT: 'Recover account',
    AUTH_SETTINGS_PAGE_TITLE: 'User settings',
    AUTH_LOGIN_PAGE_TITLE: 'Login',
    AUTH_SETTING_NEW_PASSWORD: 'Saving new password...',
    AUTH_SETTING_NEW_PASSWORD_FAILED: 'Saving password failed',
    AUTH_SETTING_NEW_PASSWORD_SUCCESS: 'Password saved',

    AUTH_RECOVER_SUCCESS_PAGE_TITLE: 'Account recovered',
    AUTH_RECOVER_SUCCESS_TITLE: 'Account as been recovered',
    AUTH_RECOVER_SUCCESS_MESSAGE: 'You have been automatically logged in.',

    AUTH_CURRENT_PASSWORD_LABEL: 'Current password',
    AUTH_PASSWORD_CHANGE_REQUEST: 'Changing password...',
    AUTH_PASSWORD_CHANGE_SUCCESS: 'Password changed',
    AUTH_PASSWORD_CHANGE_FAILED: 'Password change failed',
    AUTH_EMAIL_CHANGE_SUCCESS: '',
    AUTH_ACCOUNT_SETTINGS_TITLE: 'Account Settings',
    AUTH_ACCOUNT_CHANGE_PASSWORD: 'Change password',
    AUTH_ACCOUNT_CHANGE_EMAIL: 'Change E-Mail',
    AUTH_SHIPPING_DETAILS: 'Delivery address',
    AUTH_BTN_REGISTER_GUEST: 'Register Guest',
    AUTH_USER_DETAILS: 'User Details',
    AUTH_USER_SETTINGS_PAGE_TITLE: 'User Settings',

    GUEST_REGISTER_PAGE_TITLE: 'Guest Registration',

    MINUTES_SHORT: 'min.',

    CHECKOUT_PAGE_TITLE: 'Checkout',
    CHECKOUT_NO_ITEMS: 'Your cart is currently empty',
    CHECKOUT_PRODUCT: 'Product',
    CHECKOUT_QUANTITY: 'Quantity',
    CHECKOUT_VAT: 'VAT',
    CHECKOUT_PRICE: 'Price',
    CHECKOUT_UNIT_PRICE: 'Single price',
    CHECKOUT_TYPE_GUIDED_TOUR: 'guided tour',
    CHECKOUT_TYPE_TICKET: 'Entry ticket',
    CHECKOUT_TYPE_DAY_TICKET: 'Ticket',
    CHECKOUT_TOTAL: 'Total',
    CHECKOUT_DELETE_ARE_YOU_SURE: 'Are you sure?',
    CHECKOUT_DELETE_YES: 'Yes',
    CHECKOUT_DELETE_NO: 'No',
    CHECKOUT_BACK_TO_SHOPPING: 'Back to shopping',
    CHECKOUT_PAY_WITH: 'Pay with',
    CHECKOUT_CANCEL_ORDER: 'Cancel order',

    CHECKOUT_SUCCESS_PAGE_TITLE: 'Payment successful',
    CHECKOUT_SUCCESS_MESSAGE: 'Thank you for your order!',
    CHECKOUT_SUCCESS_TICKETS_IN_EMAIL:
      'You will also receive your tickets by email (with PayPal Express to the email address registered with PayPal)!',
    CHECKOUT_FAILED_MESSAGE: 'Your payment was not completed successfully. Please try again.',
    CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE: 'Payment accepted',
    CHECKOUT_CARD_ACCEPTED: 'Payment method was accepted – Please confirm your order!',
    CHECKOUT_CONFIRM_ORDER: 'Confirm order',
    CHECKOUT_NOT_LOGGEDIN_MESSAGE: 'You have to login before proceeding to payment',
    CHECKOUT_PAYPAL_EXPRESS_NOT_LOGGEDIN_MESSAGE: 'For other payment methods please login',
    CHECKOUT_I_ACCEPT: 'I accept',
    CHECKOUT_THE_TERMS: ' the Terms',
    CHECKOUT_THE_PRIVACY_POLICY: 'Privacy Policy',
    CHECKOUT_READ: 'Read',
    CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE: 'Payment Cancelled',
    CHECKOUT_PAYMENT_CANCELED_MESSAGE:
      'Your payment was cancelled. Do you want to clear your cart?',
    CHECKOUT_CLEAR_CART: 'Clear Cart',
    CHECKOUT_KEEP_CART: 'Keep Cart',
    CHECKOUT_CONFIRM_ORDER_PAGE_TITLE: 'Confirm your order',
    CHECKOUT_AS_GUEST_MESSAGE: 'Or you can checkout as guest for a single purchase',
    CHECKOUT_PAYPAL_EXPRESS_AS_GUEST_MESSAGE: 'or checkout as guest',
    CHECKOUT_AS_GUEST_BUTTON: 'Continue as guest',
    CHECKOUT_SHIPPING_COSTS: 'Shipping Costs',
    CHECKOUT_SEND_INVOICE: 'I need invoice',
    CHECKOUT_SEND_INVOICE_INFORMATION: 'Billing information',
    CHECKOUT_PAYPAL_EXPRESS_TITLE_SUFFIX: ' Express',
    CHECKOUT_PAYPAL_EXPRESS_INFO: 'Use your paypal details',
    CHECKOUT_CART_GONE:
      'Your cart was not found. Maybe your order was already paid for or you took too long to complete the order. Check your email for an order confirmation or try buying the items again.',
    CHECKOUT_NOT_PAYABLE: 'The shopping cart can not be completed!',
    CHECKOUT_NO_PAY: 'Continue',
    SHOPPING_CART: 'Shopping Cart',
    DIRECT_DEBIT: 'Direct Debit',
    CHECKOUT_CANCEL: 'Cancel',
    CHECKOUT_NO_SHIPPING_COSTS_FOR_COUNTRY:
      'There are no shipping costs stored for this country. Please contact us.',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_TEXT: 'Here you will find details about your',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_LINK: 'right of withdrawal',

    NAV_VOUCHERS: 'Vouchers',
    VOUCHER_PAGE_TITLE: 'Vouchers',
    VOUCHER_CATEGORY_PAGE_TITLE: 'Shop',
    VOUCHER_TOTAL: 'Total',
    VOUCHER_UNIT_PRICE: 'Per item',
    VOUCHER_ADDED_TO_CART: 'Voucher added to cart',
    VOUCHER_QUANTITY: 'Quantity',
    VOUCHER_BUY: 'Buy',
    VOUCHER_OUT_OF_STOCK: 'Item is currently out of stock',
    VOUCHER_EXPIRED: 'Item is no longer available',
    VOUCHER_BUY_SUFFIX_SINGULAR: ' voucher',
    VOUCHER_BUY_SUFFIX_PLURAL: ' vouchers',

    VOUCHER: 'Voucher',
    VOUCHER_CODE: 'Voucher code',
    VOUCHER_GET_BUTTON: 'Redeem',
    VOUCHER_REMAINING_AMOUNT: 'Remaining amount',
    VOUCHER_SELECTABLE_PRICE: 'Price freely selectable',
    VOUCHER_VALUE: 'Enter value',

    SHIPPING_TITLE: 'Shipping Address',
    SHIPPING_CHANGE: 'Change',
    SHIPPING_SAME_AS_BILLING: 'Same as billing',
    SHIPPING_FILL_ALL_FIELDS: 'Please fill in all fields for Shipping Address',
    SHIPPING_WEIGTH_FROM: 'Weight from',

    BILLING_TITLE: 'Billing information',
    BILLING_CHANGE: 'Change',
    BILLING_SHIPPING_SAVE: 'Save',

    MERCHANDISE_PAGE_TITLE: 'Shop',
    MERCHANDISE_CATEGORY_PAGE_TITLE: 'Shop',
    MERCHANDISE_TOTAL: 'Total',
    MERCHANDISE_UNIT_PRICE: 'Per item',
    MERCHANDISE_ADDED_TO_CART: 'Product added to cart',
    MERCHANDISE_QUANTITY: 'Quantity',
    MERCHANDISE_BUY: 'Buy',
    MERCHANDISE_BUY_SUFFIX_SINGULAR: 'item',
    MERCHANDISE_BUY_SUFFIX_PLURAL: 'items',
    MERCHANDISE_OUT_OF_STOCK: 'Item is currently out of stock',
    MERCHANDISE_SOLDOUT: 'sold out',

    ANNUAL_TICKETS_CARD: 'Card',
    ANNUAL_TICKETS_NAME: 'Title, First Name, Surname',
    ANNUAL_TICKETS_SENT_TO_CUSTOM_ADDRESS: 'Send to custom address',
    ANNUAL_TICKETS_ADDRESS: 'Address',

    USER_SETTINGS_SAVED: 'Settings saved',
    USER_LOGIN_PAGE_TITLE: 'Login',
    USER_REGISTER_PAGE_TITLE: 'Register',
    USER_FORGOT_PAGE_TITLE: 'Forgot credentials',
    USER_RECOVER_PAGE_TITLE: 'Recover account',
    USER_FORGOT_SUCCESS_PAGE_TITLE: 'Password reset success',
    PRODUCT_PAGE_TITLE: 'Product',

    PAGE_NOT_FOUND_TITLE: 'Page not found',
    PAGE_NOT_FOUND_MESSAGE: 'The page you were looking for was not found',

    USER_REGISTER_SUCCESS_PAGE_TITLE: 'User registered successfully',
    REGISTRATION_SUCCESS_TITLE: 'Registration accepted',
    REGISTRATION_SUCCESS_TEXT: 'Your account has now been created',

    FORGOT_PASSWORD_PAGE_TITLE: 'Forgot password',
    RECOVER_PAGE_TITLE: 'Recover account',

    FOOTER_IMPRINT_LINK: 'Imprint',
    FOOTER_TERMS_LINK_GTC: 'GTC',
    FOOTER_TERMS_LINK_DATA_PROTECTION: 'Data protection regulations',
    FOOTER_SHIPPING: 'Shipping costs',

    INTERNAL_SERVER_ERROR_MESSAGE_PREFIX:
      'An unexpected error has occured. This should never happen. Please contact us at',
    INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX: 'if you need further assistance.',
    PAYMENT_METHOD_CHOICE: 'Please select',

    REQUIRED_FIELDS: 'required fields',

    SALUTATION_PLACEHOLDER: 'Please select',

    PROGRESS_BAR_CART: 'cart',
    PROGRESS_BAR_LOGIN: 'sign in',
    PROGRESS_BAR_PAYMENT: 'payment',
    PROGRESS_BAR_COMPLETE: 'complete',

    RESET_CATEGORY: 'reset',
    SELECT_INPUT_PLACEHOLDER: 'please select...',
    COOKIE_ACCEPT: 'I accept!',

    EXTERNAL_DOCUMENT_NUMBER: 'External document number',
    EXTERNAL_NUMBER_CONFIRM: 'Confirm',
    EXTERNAL_NUMBER_CHANGE: 'Modify',
  },
  de: {
    MUSEUM: ' ',
    LANG_FULL: 'Deutsch',
    DATE_FORMAT: 'd.m.Y',

    HIGHLIGHT_TITLE: 'Herzlich Willkommen',
    HIGHLIGHT_DESCRIPTION: ' ',

    NAV_MERCHANDISE: 'Shop',
    NAV_TICKETS: 'Tickets',
    NAV_GUIDED_TOURS: 'Führungen',
    NAV_ALL: 'Alles',
    NAV_BOOKS: 'Bücher',
    NAV_POSTERS: 'Posters',
    NAV_LOGIN: 'Anmelden',
    NAV_REGISTER: 'Neu registrieren',
    NAV_SETTINGS: 'Einstellungen',
    NAV_LOGOUT: 'Abmelden',
    NAV_BACK: 'Zurück',
    NAV_BACK_TO: 'Zurück zu',
    NAV_REGISTER_AS_GUEST: 'Rechnungsanschrift',
    NAV_DAY_TICKETS: 'Tickets',
    NAV_MEMCARD: 'Jahreskarten',

    BREADCRUMBS_MUSEUM_HOME: 'Home',
    BREADCRUMBS_WEBSHOP: 'Webshop',

    GO_HOME: 'Weiter einkaufen',

    ALL_PRICES_INCLUDE_VAT: 'alle Preise inkl. gesetzl. MwSt.',
    ALL_PRICES_INCLUDE_VAT_SHORT: 'inkl. MwSt.',

    DATE_PICKER_UNAVAILABLE_DAY: 'Tag ist nicht verfügbar',
    DATE_PICKER_SOLDOUT_DAY: 'Tag ist ausverkauft',
    TIME_PICKER_SOLDOUT_TIME: 'Die Zeit ist ausverkauft',

    TICKETS_PAGE_TITLE: 'Tickets',
    TICKETS_TIME: 'Öffnungszeiten',
    // Do not edit this one pls
    TICKETS_BUY_PREFIX: ' ',
    TICKETS_BUY_SUFFIX: 'kaufen',
    TICKETS_TICKETS: 'Tickets',
    TICKETS_TICKET: 'Ticket',
    TICKETS_FROM: 'von',
    TICKETS_TO: 'bis',
    TICKETS_SELECT_A_DATE: 'Bitte wählen Sie Ihr gewünschtes Datum',
    TICKETS_ADDED_TO_CART: 'Tickets zum Warenkorb hinzugefügt',
    TICKETS_TOTAL: 'Gesamt',
    TICKETS_WRONG_FAMILY_TICKET_COUNT: 'Bitte für jedes Teilticket eine Anzahl auswählen',
    TICKETS_MOBILE_TICKETS: 'Mobile Ticket anzeigen',
    TICKETS_EXPIRED: 'Diese Veranstaltung ist vorbei!',
    TICKETS_OVERBOOKING_MSG_PART1: 'Sie versuchen ',
    TICKETS_OVERBOOKING_MSG_PART2: 'Tickets zu buchen aber es sind nur',
    TICKETS_OVERBOOKING_MSG_PART3: 'verfügbar',
    TICKETS_TIME_UNAVAILABLE: 'Tickets ausverkauft',
    TICKETS_NO_EVENTS: 'Keine Ergebnisse für diese Auswahl',
    TICKETS_NO_EVENTS_MATCHING_FILTERS: 'Keine Ergebnisse für diese Auswahl',

    DAY_TICKETS_PAGE_TITLE: 'Tickets',

    GUIDED_TOURS_PAGE_TITLE: 'Führungen',
    GUIDED_TOURS_NO_TOURS: 'Keine Touren für die ausgewählten Filter',
    GUIDED_TOURS_PRICES_FROM: 'Preis ab:',

    GUIDED_TOURS_ANY_DATE: 'Datum',
    GUIDED_TOURS_TODAY: 'Heute',
    GUIDED_TOURS_TOMORROW: 'Morgen',

    GUIDED_TOUR_SINGLE_PAGE_TITLE: 'Führungen',
    GUIDED_TOUR_SELECT_DATE: 'Bitte wählen Sie ein Datum',
    GUIDED_TOUR_SELECT_TIME: 'Bitte wählen Sie eine Zeit',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART1:
      'Diese Führung ist nur buchbar für Führungen mit mindestens ',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_SINGULAR: 'Personen',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_PLURAL: 'Personen',

    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART1: 'Sie versuchen ',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART2: 'Tickets zu buchen aber es sind nur',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART3: 'verfügbar',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART1: 'Sie versuchen Führungsplätze für',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART2: 'Personen zu buchen, es sind aber nur',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART3: 'verfügbar',
    GUIDED_TOUR_MORE_TICKETS_THAN_TOURS_MSG:
      'Die Anzahl der Tickets und Führungsplätze sollte gleich sein',
    GUIDED_TOUR_LESS_TICKETS_THAN_TOURS_MSG:
      'Die Anzahl der Tickets und Führungsplätze sollte gleich sein',

    GUIDED_TOUR_PARTICIPANTS: 'Personen',
    GUIDED_TOUR_TOTAL_PRICE: 'Gesamt',
    GUIDED_TOUR_AVAILABLE_TICKETS_FOR_SELECTED_TIME: 'Verfügbare Tickets für ausgewählte Zeit',
    GUIDED_TOUR_AVAILABLE_PARTICIPANTS_FOR_SELECTED_TIME:
      'Verfügbare Führungsplätze für ausgewählte Zeit',
    GUIDED_TOUR_FILTER_LANGUAGE: 'Sprache',
    GUIDED_TOUR_NOT_AVAILABLE: 'Führung nicht verfügbar',
    GUIDED_TOUR_SOLD_OUT: 'Diese Führung ist ausverkauft',
    GUIDED_TOUR_NO_TICKET_AVAILABLE: '',
    GUIDED_TOUR_BUY_TICKETS_PREFIX: 'Führung mit',
    GUIDED_TOUR_BUY_TICKETS_SUFFIX: 'Tickets kaufen',
    GUIDED_TOUR_BUY: 'Kaufen',
    GUIDED_TOUR_ADDED_TO_CART: 'Führung zum Warenkorb hinzugefügt',
    GUIDED_TOUR_VAT: 'MwSt.',
    GUIDED_TOUR_QUESTIONS: 'Zusatzangaben zur Gruppe',
    GUIDED_TOUR_NOT_AVAILABLE_THIS_DAY_BUT_AVAILABLE_ON:
      'Die Führung ist für das ausgewählte Datum nicht verfügbar. Der nächste verfügbare Termin ist der',
    GUIDED_TOUR_SHOW_ME: 'anzeigen',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_1: 'von',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_2: 'Touren ausgewählt. Bietet Platz für maximal',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_3: 'Teilnehmer.',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B1: 'Führungen mit max.',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B2: 'Teilnehmern pro Führung',
    GUIDED_TOUR_IS_NOT_BOOKABLE: 'derzeit nicht buchbar',

    FIELD_IS_REQUIRED: 'Bitte beantworten Sie alle Pflichtfelder.',

    AUTH_EMAIL_LABEL: 'E-Mail',
    AUTH_EMAIL_CONFIRM_LABEL: 'E-Mail-Bestätigung',
    AUTH_EMAILS_DO_NOT_MATCH: 'Die E-Mail-Adressen stimmen nicht überein',
    AUTH_PASSWORD_LABEL: 'Passwort',
    AUTH_PASSWORD_CONFIRM_LABEL: 'Passwortbestätigung',
    AUTH_PASSWORDS_DO_NOT_MATCH: 'Die Passwörter stimmen nicht überein',
    AUTH_COMPANY_LABEL: 'Firma',
    AUTH_PHONE_LABEL: 'Telefon',
    AUTH_FIRST_NAME_LABEL: 'Vorname',
    AUTH_LAST_NAME_LABEL: 'Nachname',
    AUTH_CITY_LABEL: 'Ort',
    AUTH_ADDRESS_LABEL: 'Adresse (Straße, Hausnummer)',
    AUTH_COUNTRY_LABEL: 'Land',
    AUTH_ZIP_LABEL: 'PLZ',
    AUTH_FORM_HAS_ERRORS: 'Bitte korrigieren Sie die entsprechenden Felder',
    AUTH_NAME: 'Name',

    AUTH_BTN_LOGIN: 'Anmelden',
    AUTH_BTN_REGISTER: 'Neu registrieren',
    AUTH_BTN_SAVE: 'Speichern',
    AUTH_SALUTATION_LABEL: 'Anrede',
    AUTH_ACCOUNT_SETTINGS_TITLE: 'E-Mail ändern',
    AUTH_ACCOUNT_CHANGE_PASSWORD: 'Passwort ändern',
    AUTH_ACCOUNT_CHANGE_EMAIL: 'E-Mail ändern',
    AUTH_SHIPPING_DETAILS: 'Lieferanschrift',
    AUTH_BTN_RECOVER: 'Wiederherstellen',
    AUTH_BTN_REGISTER_GUEST: 'als Gast buchen',
    AUTH_USER_DETAILS: 'Benutzerangaben',
    AUTH_USER_SETTINGS_PAGE_TITLE: 'Benutzereinstellungen',

    AUTH_FORGOT_PASSWORD: 'Passwort vergessen?',
    AUTH_DONT_HAVE_ACCOUNT_QUESTION: 'Haben Sie noch kein Konto?',
    AUTH_ALREADY_HAVE_ACCOUNT_QUESTION: 'Haben Sie schon ein Konto?',
    AUTH_TRIED_TO_ACCESS_UNAUTHORIZED_PAGE: 'Diese Seite benötigt ein Login',
    AUTH_SENT_WRONG_CREDENTIALS: 'Benutzername/E-Mail oder Passwort falsch!',

    AUTH_FORGOT_INVALID_EMAIL: 'E-Mail nicht gültig',

    AUTH_LOGGING_IN: 'Anmeldung läuft',
    AUTH_LOGIN_SUCCESS: 'Anmeldung erfolgreich',
    AUTH_LOGIN_FAILED: 'Anmeldung fehlgeschlagen',
    AUTH_REQUESTING_RESET: 'Passwort zurücksetzen',
    AUTH_REQUEST_RESET_SUCCESS: 'Passwortänderung erfolgreich',
    AUTH_REQUEST_RESET_FAIL: 'Passwortänderung nicht erfolgreich',
    AUTH_SUCCESS_RESET_TITLE: 'Passwortänderung erfolgreich',
    AUTH_SUCCESS_RESET_MESSAGE: 'Sie erhalten eine E-Mail mit Anweisungen',
    AUTH_NEW_PASSWORD_LABEL: 'Neues Passwort',
    AUTH_RECOVER_ACCOUNT: 'Passwort anfordern',
    AUTH_SETTINGS_PAGE_TITLE: 'Einstellungen',
    AUTH_LOGIN_PAGE_TITLE: 'Anmelden',
    AUTH_SETTING_NEW_PASSWORD: 'Neues Passwort wird abgespeichert...',
    AUTH_SETTING_NEW_PASSWORD_FAILED: 'Nicht erfolgreich',
    AUTH_SETTING_NEW_PASSWORD_SUCCESS: 'Passwort ist gespeichert',

    AUTH_RECOVER_SUCCESS_PAGE_TITLE: 'Konto wiederhergestellt',
    AUTH_RECOVER_SUCCESS_TITLE: 'Konto wurde wiederhergestellt',
    AUTH_RECOVER_SUCCESS_MESSAGE: 'Sie sind automatisch angemeldet.',

    AUTH_CURRENT_PASSWORD_LABEL: 'Aktuelles Passwort',
    AUTH_PASSWORD_CHANGE_REQUEST: 'Passwort wird abgespeichert...',
    AUTH_PASSWORD_CHANGE_SUCCESS: 'Passwort geändert',
    AUTH_PASSWORD_CHANGE_FAILED: 'Nicht erfolgreich',
    AUTH_EMAIL_CHANGE_SUCCESS: '',

    GUEST_REGISTER_PAGE_TITLE: 'Rechnungsanschrift',

    MINUTES_SHORT: 'min.',

    CHECKOUT_PAGE_TITLE: 'Checkout',
    CHECKOUT_NO_ITEMS: 'Ihr Warenkorb ist zur Zeit leer',
    CHECKOUT_PRODUCT: 'Artikel',
    CHECKOUT_QUANTITY: 'Anzahl',
    CHECKOUT_VAT: 'MwSt.',
    CHECKOUT_PRICE: 'Preis',
    CHECKOUT_UNIT_PRICE: 'Einzelpreis',
    CHECKOUT_TYPE_GUIDED_TOUR: 'Führung',
    CHECKOUT_TYPE_TICKET: 'Eintritts-Ticket',
    CHECKOUT_TYPE_DAY_TICKET: 'Ticket',
    CHECKOUT_TOTAL: 'Gesamt',
    CHECKOUT_DELETE_ARE_YOU_SURE: 'Sind Sie sicher?',
    CHECKOUT_DELETE_YES: 'Ja',
    CHECKOUT_DELETE_NO: 'Nein',
    CHECKOUT_BACK_TO_SHOPPING: 'Weiter einkaufen',
    CHECKOUT_PAY_WITH: 'Bezahlen mit',
    CHECKOUT_CANCEL_ORDER: 'Abbrechen',

    CHECKOUT_SUCCESS_PAGE_TITLE: 'Zahlung erfolgreich',
    CHECKOUT_SUCCESS_MESSAGE: 'Vielen Dank für Ihre Bestellung!',
    CHECKOUT_SUCCESS_TICKETS_IN_EMAIL:
      'Sie erhalten Ihre Tickets auch per E-Mail (bei PayPal-Express an die bei PayPal registrierte E-Mail-Adresse)!',
    CHECKOUT_FAILED_MESSAGE: 'Zahlung nicht erfolgreich',
    CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE: 'Zahlungart akzeptiert',
    CHECKOUT_CARD_ACCEPTED: 'Zahlungsdaten bestätigt – Bitte Bestellung abschließen!',
    CHECKOUT_CONFIRM_ORDER: 'Zahlungspflichtig bestellen',
    CHECKOUT_NOT_LOGGEDIN_MESSAGE: 'Bitte melden Sie sich an, bevor Sie mit der Zahlung fortfahren',
    CHECKOUT_PAYPAL_EXPRESS_NOT_LOGGEDIN_MESSAGE:
      'Für andere Zahlungsarten melden Sie sich bitte an',
    CHECKOUT_I_ACCEPT: 'Ich akzeptiere',
    CHECKOUT_THE_TERMS: 'die AGB',
    CHECKOUT_THE_PRIVACY_POLICY: 'Datenschutzbestimmungen',
    CHECKOUT_READ: 'Lesen',
    CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE: 'Bestellung abgebrochen',
    CHECKOUT_PAYMENT_CANCELED_MESSAGE:
      'Ihre Bezahlung wurde abgebrochen. Möchten Sie den Warenkorb leeren?',
    CHECKOUT_CLEAR_CART: 'Warenkorb leeren',
    CHECKOUT_KEEP_CART: 'Zurück zum Warenkorb',
    CHECKOUT_CONFIRM_ORDER_PAGE_TITLE: 'Bestellung prüfen und abschicken',
    CHECKOUT_AS_GUEST_MESSAGE: 'Sie können auch als Gast buchen',
    CHECKOUT_PAYPAL_EXPRESS_AS_GUEST_MESSAGE: 'oder checken als Gast aus',
    CHECKOUT_AS_GUEST_BUTTON: 'Als Gast buchen',
    CHECKOUT_SHIPPING_COSTS: 'Versandkosten',
    CHECKOUT_SEND_INVOICE: 'Rechnung erwünscht',
    CHECKOUT_SEND_INVOICE_INFORMATION: 'Rechnungsanschrift',
    CHECKOUT_PAYPAL_EXPRESS_TITLE_SUFFIX: ' Express (Daten übernehmen)',
    CHECKOUT_PAYPAL_EXPRESS_INFO: 'Use your paypal details',
    CHECKOUT_CART_GONE:
      'Ihr Warenkorb ist nicht mehr vorhanden. Entweder Ihre Bestellung ist bereits abgeschlossen oder der Vorgang hat zu lange gedauert. Bitte überprüfen Sie Ihre Emails auf eine Buchungsbestätigung oder versuchen es später erneut.',
    CHECKOUT_NOT_PAYABLE: 'Der Warenkorb kann nicht abgeschlossen werden!',
    CHECKOUT_NO_PAY: 'Weiter',
    SHOPPING_CART: 'Warenkorb',
    DIRECT_DEBIT: 'Lastschrift',
    CHECKOUT_CANCEL: 'Stornieren',
    CHECKOUT_NO_SHIPPING_COSTS_FOR_COUNTRY:
      'Für dieses Land sind keine Portokosten hinterlegt. Bitte nehmen Sie mit uns Kontakt auf.',
    CHECKOUT_COMPLETE_ORDER: 'Bestellung abschließen',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_TEXT: 'Hier finden Sie Einzelheiten zu Ihrem',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_LINK: 'Widerrufsrecht',

    NAV_VOUCHERS: 'Gutscheine',
    VOUCHER_PAGE_TITLE: 'Gutscheine',
    VOUCHER_CATEGORY_PAGE_TITLE: 'Gutscheine',
    VOUCHER_TOTAL: 'Gesamt',
    VOUCHER_ADDED_TO_CART: 'Artikel wurde dem Warenkorb hinzugefügt',
    VOUCHER_UNIT_PRICE: 'pro Stück',
    VOUCHER_QUANTITY: 'Anzahl',
    VOUCHER_BUY: ' ',
    VOUCHER_OUT_OF_STOCK: 'Artikel nicht verfügbar',
    VOUCHER_EXPIRED: 'Artikel ist nicht mehr verfügbar',
    VOUCHER_BUY_SUFFIX_SINGULAR: ' Gutschein kaufen',
    VOUCHER_BUY_SUFFIX_PLURAL: ' Gutscheine kaufen',

    VOUCHER: 'Gutschein',
    VOUCHER_CODE: 'Gutscheincode',
    VOUCHER_GET_BUTTON: 'Einlösen',
    VOUCHER_REMAINING_AMOUNT: 'Restwert',
    VOUCHER_SELECTABLE_PRICE: 'Preis frei wählbar',
    VOUCHER_VALUE: 'Wert eingeben',

    SHIPPING_TITLE: 'Lieferanschrift',
    SHIPPING_CHANGE: 'Ändern',
    SHIPPING_SAME_AS_BILLING: 'Gleiche Lieferanschrift',
    SHIPPING_FILL_ALL_FIELDS: 'Bitte füllen Sie alle Felder zur Lieferanschrift aus',
    SHIPPING_WEIGTH_FROM: 'Gewicht ab',

    BILLING_TITLE: 'Rechnungsanschrift',
    BILLING_CHANGE: 'Ändern',
    BILLING_SHIPPING_SAVE: 'Speichern',

    MERCHANDISE_PAGE_TITLE: 'Shop',
    MERCHANDISE_CATEGORY_PAGE_TITLE: 'Shop',
    MERCHANDISE_TOTAL: 'Gesamt',
    MERCHANDISE_ADDED_TO_CART: 'Artikel wurde dem Warenkorb hinzugefügt',
    MERCHANDISE_UNIT_PRICE: 'pro Stück',
    MERCHANDISE_QUANTITY: 'Anzahl',
    MERCHANDISE_BUY: ' ',
    MERCHANDISE_BUY_SUFFIX_SINGULAR: 'Artikel kaufen',
    MERCHANDISE_BUY_SUFFIX_PLURAL: 'Artikel kaufen',
    MERCHANDISE_OUT_OF_STOCK: 'Artikel nicht verfügbar',
    MERCHANDISE_SOLDOUT: 'ausverkauft',

    ANNUAL_TICKETS_CARD: 'Karte',
    ANNUAL_TICKETS_NAME: 'Titel, Vorname, Nachname',
    ANNUAL_TICKETS_SENT_TO_CUSTOM_ADDRESS: 'An benutzerdefinierte Adresse senden',
    ANNUAL_TICKETS_ADDRESS: 'Adresse',

    USER_SETTINGS_SAVED: 'Einstellungen gespeichert',
    USER_LOGIN_PAGE_TITLE: 'Anmelden',
    USER_REGISTER_PAGE_TITLE: 'Neu registrieren',
    USER_FORGOT_PAGE_TITLE: 'Anmeldedaten vergessen',
    USER_RECOVER_PAGE_TITLE: 'Konto wiederherstellen',
    USER_FORGOT_SUCCESS_PAGE_TITLE: 'Passwort-Reset war erfolgreich',
    PRODUCT_PAGE_TITLE: 'Artikel',

    PAGE_NOT_FOUND_TITLE: 'Seite nicht gefunden',
    PAGE_NOT_FOUND_MESSAGE: 'Die Seite konnte nicht gefunden werden',

    USER_REGISTER_SUCCESS_PAGE_TITLE: 'Anmeldung erfolgreich',
    REGISTRATION_SUCCESS_TITLE: 'Anmeldung akzeptiert',
    REGISTRATION_SUCCESS_TEXT: 'Ihr Konto wurde erstellt',

    FORGOT_PASSWORD_PAGE_TITLE: 'Passwort vergessen',
    RECOVER_PAGE_TITLE: 'Konto wiederherstellen',

    FOOTER_IMPRINT_LINK: 'Impressum',
    FOOTER_TERMS_LINK_GTC: 'AGB',
    FOOTER_TERMS_LINK_DATA_PROTECTION: 'Datenschutzbestimmungen',
    FOOTER_SHIPPING: 'Versandkosten',

    INTERNAL_SERVER_ERROR_MESSAGE_PREFIX:
      'Ein unerwarteter Fehler ist aufgetreten. Für weitere Unterstützung kontaktieren Sie uns bitte über ',
    INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX: '.',
    PAYMENT_METHOD_CHOICE: 'Bitte auswählen',

    REQUIRED_FIELDS: 'Pflichtangaben',

    SALUTATION_PLACEHOLDER: 'Bitte auswählen',

    PROGRESS_BAR_CART: 'warenkorb',
    PROGRESS_BAR_LOGIN: 'anmelden ',
    PROGRESS_BAR_PAYMENT: 'zahlung',
    PROGRESS_BAR_COMPLETE: 'abschluss',

    RESET_CATEGORY: 'zurücksetzen',
    SELECT_INPUT_PLACEHOLDER: 'bitte auswählen...',
    COOKIE_ACCEPT: 'Ich stimme zu!',

    EXTERNAL_DOCUMENT_NUMBER: 'Externe Dokumentennummer',
    EXTERNAL_NUMBER_CONFIRM: 'Bestätigen',
    EXTERNAL_NUMBER_CHANGE: 'Ändern',
  },
  fr: {
    MUSEUM: 'Musée',
    LANG_FULL: 'Français',
    DATE_FORMAT: 'd.m.Y',

    HIGHLIGHT_TITLE: 'Accueillir',
    HIGHLIGHT_DESCRIPTION: ' ',

    NAV_MERCHANDISE: 'Shop',
    NAV_TICKETS: 'Billet',
    NAV_GUIDED_TOURS: 'Visites guidées',
    NAV_ALL: 'Tous',
    NAV_BOOKS: 'Livres',
    NAV_POSTERS: 'Posters',
    NAV_LOGIN: 'Login',
    NAV_REGISTER: "S'inscrire",
    NAV_SETTINGS: 'Paramètres',
    NAV_LOGOUT: 'Déconnexion',
    NAV_BACK: 'Retour',
    NAV_BACK_TO: 'Retour à',
    NAV_REGISTER_AS_GUEST: 'Détails de facturation',
    NAV_DAY_TICKETS: 'Tickets',
    NAV_MEMCARD: 'Billets annuels',

    BREADCRUMBS_MUSEUM_HOME: 'Home',
    BREADCRUMBS_WEBSHOP: 'Boutique en ligne',

    GO_HOME: 'Retour à la boutique',

    ALL_PRICES_INCLUDE_VAT: 'tous les prix incluent la TVA',
    ALL_PRICES_INCLUDE_VAT_SHORT: 'incl. la TVA',

    DATE_PICKER_UNAVAILABLE_DAY: "Le jour n'est pas disponible",
    DATE_PICKER_SOLDOUT_DAY: 'La journée est épuisée',
    TIME_PICKER_SOLDOUT_TIME: 'Le temps est épuisé',

    TICKETS_PAGE_TITLE: 'Tickets',
    TICKETS_TIME: 'Temps',
    TICKETS_BUY_PREFIX: 'Acheter',
    TICKETS_BUY_SUFFIX: ' ',
    TICKETS_TICKETS: 'Billet',
    TICKETS_TICKET: 'Billet',
    TICKETS_FROM: 'from',
    TICKETS_TO: 'to',
    TICKETS_SELECT_A_DATE: 'Veuillez sélectionner la date souhaitée',
    TICKETS_ADDED_TO_CART: 'Billets ajoutés au panier',
    TICKETS_TOTAL: 'Total',
    TICKETS_WRONG_FAMILY_TICKET_COUNT: 'Veuillez sélectioner une quantité pour chaque billet',
    TICKETS_MOBILE_TICKETS: 'Montrer les billets électroniques ',
    TICKETS_EXPIRED: 'Cet événement est terminé!',
    TICKETS_OVERBOOKING_MSG_PART1: 'Essayez de réserver',
    TICKETS_OVERBOOKING_MSG_PART2: "tickets mais il n'y avait que",
    TICKETS_OVERBOOKING_MSG_PART3: 'disponible',
    TICKETS_TIME_UNAVAILABLE: 'Billets épuisés',
    TICKETS_NO_EVENTS: 'Aucun résultat pour cette sélection',
    TICKETS_NO_EVENTS_MATCHING_FILTERS: 'Aucun résultat pour cette sélection',

    DAY_TICKETS_PAGE_TITLE: 'Tickets',

    GUIDED_TOURS_PAGE_TITLE: 'Visite guidée',
    GUIDED_TOURS_NO_TOURS: 'Pas de visites pour les filtres sélectionnés',
    GUIDED_TOURS_PRICES_FROM: 'Prix de:',

    GUIDED_TOURS_ANY_DATE: 'Date',
    GUIDED_TOURS_TODAY: "Aujourd'hui",
    GUIDED_TOURS_TOMORROW: 'Demain',

    GUIDED_TOUR_SINGLE_PAGE_TITLE: 'Visite guidée',
    GUIDED_TOUR_SELECT_DATE: 'Sélectionnez un jour',
    GUIDED_TOUR_SELECT_TIME: 'Choisissez la période',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART1:
      'Cette visite est disponible uniquement pour les groupes de',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_SINGULAR: 'personne',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_PLURAL: 'personnes',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART1: 'Essayez de réserver',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART2: "tickets mais il n'y avait que",
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART3: 'disponible',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART1: 'Vous essayez de réserver pour',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART2: 'participants, mais seulement',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART3: 'places sont disponibles',
    GUIDED_TOUR_MORE_TICKETS_THAN_TOURS_MSG:
      'The number of tickets and participants should be the same',
    GUIDED_TOUR_LESS_TICKETS_THAN_TOURS_MSG:
      'The number of tickets and participants should be the same',

    GUIDED_TOUR_PARTICIPANTS: 'personnes',
    GUIDED_TOUR_TOTAL_PRICE: 'Totalement',
    GUIDED_TOUR_AVAILABLE_TICKETS_FOR_SELECTED_TIME:
      'Les billets disponibles pour le temps sélectionné',
    GUIDED_TOUR_AVAILABLE_PARTICIPANTS_FOR_SELECTED_TIME: '',
    GUIDED_TOUR_FILTER_LANGUAGE: 'Langue',
    GUIDED_TOUR_NOT_AVAILABLE: 'Tour non disponible',
    GUIDED_TOUR_SOLD_OUT: 'Ce tour est complet',
    GUIDED_TOUR_NO_TICKET_AVAILABLE: '',
    GUIDED_TOUR_BUY_TICKETS_PREFIX: 'Acheter',
    GUIDED_TOUR_BUY_TICKETS_SUFFIX: 'billets',
    GUIDED_TOUR_BUY: 'Acheter',
    GUIDED_TOUR_ADDED_TO_CART: 'Tour ajouté au panier',
    GUIDED_TOUR_VAT: 'T.V.A',
    GUIDED_TOUR_QUESTIONS: 'Informations complémentaires sur le groupe',
    GUIDED_TOUR_NOT_AVAILABLE_THIS_DAY_BUT_AVAILABLE_ON:
      "La visite n'est pas disponible pour la date sélectionnée. La prochaine date disponible est le",
    GUIDED_TOUR_SHOW_ME: 'afficher',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_1: 'des',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_2: 'circuits sélectionnés. Accueille un maximum de',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_3: 'participants.',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B1: 'visites guidées avec un maximum de',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B2: 'participants par visite guidée',
    GUIDED_TOUR_IS_NOT_BOOKABLE: 'actuellement non réservable',

    FIELD_IS_REQUIRED: 'Veuillez répondre à tous les champs obligatoires.',

    AUTH_EMAIL_LABEL: 'émail',
    AUTH_EMAIL_CONFIRM_LABEL: 'confirmez email',
    AUTH_EMAILS_DO_NOT_MATCH: 'Les adresses e-mail ne correspondent pas',
    AUTH_PASSWORD_LABEL: 'mot de passe',
    AUTH_PASSWORD_CONFIRM_LABEL: 'confirmer le mot de passe:',
    AUTH_PASSWORDS_DO_NOT_MATCH: 'Les mots de passe ne correspondent pas',
    AUTH_SALUTATION_LABEL: 'Titre',
    AUTH_COMPANY_LABEL: 'Société',
    AUTH_PHONE_LABEL: 'Téléphone',
    AUTH_FIRST_NAME_LABEL: 'Prénom',
    AUTH_LAST_NAME_LABEL: 'Nom de famille',
    AUTH_CITY_LABEL: 'Ville',
    AUTH_ADDRESS_LABEL: 'Adresse (Rue, Numéro de maison)',
    AUTH_COUNTRY_LABEL: 'Pays',
    AUTH_ZIP_LABEL: 'Code postal',
    AUTH_FORM_HAS_ERRORS: 'Le formulaire contient des erreurs',
    AUTH_NAME: 'Nom',

    AUTH_BTN_LOGIN: 'Login',
    AUTH_BTN_REGISTER: "S'inscrire",
    AUTH_BTN_SAVE: 'Enregistrer',

    AUTH_FORGOT_PASSWORD: 'Mot de passe oublié?',
    AUTH_DONT_HAVE_ACCOUNT_QUESTION: 'Ne pas avoir un compte?',
    AUTH_ALREADY_HAVE_ACCOUNT_QUESTION: 'Vous avez déjà un compte?',
    AUTH_TRIED_TO_ACCESS_UNAUTHORIZED_PAGE: 'Cette page nécessite une login',
    AUTH_SENT_WRONG_CREDENTIALS: 'email ou mot de passe sont incorrects ',

    AUTH_FORGOT_INVALID_EMAIL: 'email non valide',

    AUTH_LOGGING_IN: 'Logging in',
    AUTH_LOGIN_SUCCESS: 'Login Successful',
    AUTH_LOGIN_FAILED: 'Login Failed',
    AUTH_REQUESTING_RESET: 'Requesting Reset',
    AUTH_REQUEST_RESET_SUCCESS: 'Password Reset Successful',
    AUTH_REQUEST_RESET_FAIL: 'Password Reset Failed',
    AUTH_SUCCESS_RESET_TITLE: 'Réinitialisation du mot de passe réussi',
    AUTH_SUCCESS_RESET_MESSAGE: 'Réussi',
    AUTH_NEW_PASSWORD_LABEL: 'Nouveau mot de passe',
    AUTH_RECOVER_ACCOUNT: 'Récupérer le compte',
    AUTH_SETTINGS_PAGE_TITLE: 'Paramètres utilisateur',
    AUTH_LOGIN_PAGE_TITLE: 'login',
    AUTH_SETTING_NEW_PASSWORD: "Enregistrement d'un nouveau mot de passe",
    AUTH_SETTING_NEW_PASSWORD_FAILED: 'Enregistrer le mot de passe échoué',
    AUTH_SETTING_NEW_PASSWORD_SUCCESS: 'Mot de passe sauvegardé',

    AUTH_RECOVER_SUCCESS_PAGE_TITLE: 'Compte récupéré',
    AUTH_RECOVER_SUCCESS_TITLE: 'Compte a été récupéré',
    AUTH_RECOVER_SUCCESS_MESSAGE: 'Vous avez été automatiquement connecté',

    AUTH_CURRENT_PASSWORD_LABEL: 'Mot de passe actuel',
    AUTH_PASSWORD_CHANGE_REQUEST: 'Modification du mot de passe...',
    AUTH_PASSWORD_CHANGE_SUCCESS: 'Changement de mot de passe',
    AUTH_PASSWORD_CHANGE_FAILED: 'Changement de mot de passe échoué',
    AUTH_EMAIL_CHANGE_SUCCESS: '',
    AUTH_ACCOUNT_SETTINGS_TITLE: '',
    AUTH_ACCOUNT_CHANGE_PASSWORD: '',
    AUTH_ACCOUNT_CHANGE_EMAIL: '',
    AUTH_SHIPPING_DETAILS: 'Adresse de livraison',
    AUTH_PASSWORD_CHANGE_TITLE: 'Mot de passe',
    AUTH_BTN_REGISTER_GUEST: 'Réserver comme invité',
    AUTH_USER_DETAILS: "Données d'utilisateur",
    AUTH_USER_SETTINGS_PAGE_TITLE: 'User Settings',

    AUTH_BTN_RECOVER: 'Recover',

    GUEST_REGISTER_PAGE_TITLE: 'Détails de facturation',

    MINUTES_SHORT: 'min.',

    CHECKOUT_PAGE_TITLE: 'Checkout',
    CHECKOUT_NO_ITEMS: 'Votre panier est actuellement vide',
    CHECKOUT_PRODUCT: 'Article',
    CHECKOUT_QUANTITY: 'Quantité',
    CHECKOUT_VAT: 'TVA',
    CHECKOUT_PRICE: 'Prix',
    CHECKOUT_UNIT_PRICE: 'Prix unitaire',
    CHECKOUT_TYPE_GUIDED_TOUR: 'Tour guidée',
    CHECKOUT_TYPE_TICKET: 'Entry ticket',
    CHECKOUT_TYPE_DAY_TICKET: 'Ticket',
    CHECKOUT_TOTAL: 'Totalement',
    CHECKOUT_DELETE_ARE_YOU_SURE: 'Êtes-vous sûr?',
    CHECKOUT_DELETE_YES: 'Oui',
    CHECKOUT_DELETE_NO: 'Non',
    CHECKOUT_BACK_TO_SHOPPING: 'Continuer vos achats',
    CHECKOUT_PAY_WITH: 'Payer avec',

    CHECKOUT_SUCCESS_PAGE_TITLE: 'Paiement réussi',
    CHECKOUT_SUCCESS_MESSAGE: 'Paiement achevé',
    CHECKOUT_SUCCESS_TICKETS_IN_EMAIL:
      "Vous recevrez également vos billets par e-mail (avec PayPal Express à l'adresse e-mail enregistrée auprès de PayPal)!",
    CHECKOUT_FAILED_MESSAGE: "Votre paiement n'a pas été effectué. Veuillez réessayer",
    CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE: 'Paiement accepté',
    CHECKOUT_CARD_ACCEPTED: 'Votre carte a été acceptée',
    CHECKOUT_CONFIRM_ORDER: "Confirmer l'ordre",
    CHECKOUT_CANCEL_ORDER: 'Annuler la commande',
    CHECKOUT_NOT_LOGGEDIN_MESSAGE: 'Vous devez vous connecter avant de procéder au paiement',
    CHECKOUT_PAYPAL_EXPRESS_NOT_LOGGEDIN_MESSAGE:
      'Pour les autres modes de paiement, veuillez vous connecter',
    CHECKOUT_I_ACCEPT: "J'accepte",
    CHECKOUT_THE_TERMS: "les conditions d'uitlisation",
    CHECKOUT_THE_PRIVACY_POLICY: 'la politique de confidentialité',
    CHECKOUT_READ: 'lire',
    CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE: 'Paiement annulé',
    CHECKOUT_PAYMENT_CANCELED_MESSAGE:
      'Votre paiement a été annulé. Voulez-vous vider votre panier?',
    CHECKOUT_CLEAR_CART: 'Vider le panier',
    CHECKOUT_KEEP_CART: 'Garder le panier',
    CHECKOUT_CONFIRM_ORDER_PAGE_TITLE: 'Valider ma commande',
    CHECKOUT_AS_GUEST_MESSAGE: "Commander en tant qu'invité",
    CHECKOUT_PAYPAL_EXPRESS_AS_GUEST_MESSAGE: "ou consultez en tant qu'invité",
    CHECKOUT_AS_GUEST_BUTTON: "Commander en tant qu'invité",
    CHECKOUT_SHIPPING_COSTS: 'livraison',
    CHECKOUT_SEND_INVOICE: 'Facture désirée',
    CHECKOUT_SEND_INVOICE_INFORMATION: 'Données de facturation',
    CHECKOUT_PAYPAL_EXPRESS_TITLE_SUFFIX: ' Express',
    CHECKOUT_PAYPAL_EXPRESS_INFO: 'Use your paypal details',
    CHECKOUT_CART_GONE:
      "Votre panier est introuvable. Soit votre commande est déjà aboutie, soit trop de temps s'est écoulé avant le checkout. Veuillez contrôler vos e-mails. Si vous n'avez pas reçu de confirmation de commande, essayer à nouveau plus tard.",
    CHECKOUT_NOT_PAYABLE: 'Le panier ne peut pas être complété!',
    CHECKOUT_NO_PAY: 'Continuer',
    SHOPPING_CART: '',
    DIRECT_DEBIT: 'Avis de Prélèvement',
    CHECKOUT_CANCEL: 'Annuler',
    CHECKOUT_NO_SHIPPING_COSTS_FOR_COUNTRY:
      "Il n'y a pas de frais de port pour ce pays. Contactez nous s'il vous plait.",
    CHECKOUT_RIGHT_OF_WITHDRAWAL_TEXT: 'Vous trouverez ici les détails de votre',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_LINK: 'droit de rétractation',

    NAV_VOUCHERS: 'Coupons',
    VOUCHER_PAGE_TITLE: 'Coupons',
    VOUCHER_CATEGORY_PAGE_TITLE: 'Coupons',
    VOUCHER_TOTAL: 'Total',
    VOUCHER_ADDED_TO_CART: 'Produit ajouté au panier',
    VOUCHER_UNIT_PRICE: 'Par objet',
    VOUCHER_QUANTITY: 'Quantité',
    VOUCHER_BUY: 'Acheter',
    VOUCHER_OUT_OF_STOCK: "L'article n'est actuellement pas disponible",
    VOUCHER_EXPIRED: "L'article n'est plus disponible",
    VOUCHER_BUY_SUFFIX_SINGULAR: ' coupon',
    VOUCHER_BUY_SUFFIX_PLURAL: ' coupons',
    VOUCHER_SELECTABLE_PRICE: 'Prix à choisir librement',

    VOUCHER: 'Coupon',
    VOUCHER_CODE: 'Code de coupon',
    VOUCHER_GET_BUTTON: 'Racheter',
    VOUCHER_REMAINING_AMOUNT: 'Résiduel',
    VOUCHER_VALUE: 'Entrer la valeur',

    SHIPPING_TITLE: 'Adresse de livraison',
    SHIPPING_CHANGE: 'Changer',
    SHIPPING_SAME_AS_BILLING: "Livrer à l'adresse de facturation",
    SHIPPING_FILL_ALL_FIELDS: '',
    SHIPPING_WEIGTH_FROM: 'Poids à partir de',

    BILLING_TITLE: 'Informations de facturation',
    BILLING_CHANGE: 'Changer',
    BILLING_SHIPPING_SAVE: 'Enregistrer',

    MERCHANDISE_PAGE_TITLE: 'Merchandise',
    MERCHANDISE_CATEGORY_PAGE_TITLE: 'Merchandise',
    MERCHANDISE_TOTAL: 'Total',
    MERCHANDISE_ADDED_TO_CART: 'Produit ajouté au panier',
    MERCHANDISE_UNIT_PRICE: 'Par objet',
    MERCHANDISE_QUANTITY: 'Quantité',
    MERCHANDISE_BUY: 'Acheter',
    MERCHANDISE_BUY_SUFFIX_SINGULAR: 'article',
    MERCHANDISE_BUY_SUFFIX_PLURAL: 'articles',
    MERCHANDISE_OUT_OF_STOCK: "L'article n'est actuellement pas disponible",
    MERCHANDISE_SOLDOUT: 'épuisé',

    ANNUAL_TICKETS_CARD: 'Carte',
    ANNUAL_TICKETS_NAME: 'Titre, Prénom, Nom',
    ANNUAL_TICKETS_SENT_TO_CUSTOM_ADDRESS: 'Envoyer à une adresse personnalisée',
    ANNUAL_TICKETS_ADDRESS: 'Adresse',

    USER_SETTINGS_SAVED: 'Paramètres sauvegardés',
    USER_LOGIN_PAGE_TITLE: 'Login',
    USER_REGISTER_PAGE_TITLE: "S'inscrire",
    USER_FORGOT_PAGE_TITLE: 'Oublié votre mot',
    USER_RECOVER_PAGE_TITLE: 'Récupérer le compte',
    USER_FORGOT_SUCCESS_PAGE_TITLE: 'Password Reset Success',
    PRODUCT_PAGE_TITLE: 'Article',

    PAGE_NOT_FOUND_TITLE: 'Page introuvable',
    PAGE_NOT_FOUND_MESSAGE: "La page n'a pas été trouvé",

    USER_REGISTER_SUCCESS_PAGE_TITLE: 'Connexion réussie',
    REGISTRATION_SUCCESS_TITLE: "Accepté l'inscription",
    REGISTRATION_SUCCESS_TEXT: 'Votre compte a été créé',

    FORGOT_PASSWORD_PAGE_TITLE: 'Mot de passe oublié',
    RECOVER_PAGE_TITLE: 'Récupérer compte',

    FOOTER_IMPRINT_LINK: 'Imprint',
    FOOTER_TERMS_LINK_GTC: 'Conditions',
    FOOTER_TERMS_LINK_DATA_PROTECTION: 'Confidentialité',
    FOOTER_SHIPPING: 'Frais de livraison',

    INTERNAL_SERVER_ERROR_MESSAGE_PREFIX:
      'An unexpected error has occured. This should never happen. Please contact us at',
    INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX: 'if you need further assistance.',
    PAYMENT_METHOD_CHOICE: 'Sélectionner un moyen de paiement',
    REQUIRED_FIELDS: 'Champs obligatoires',

    SALUTATION_PLACEHOLDER: 'Veuillez choisir',

    PROGRESS_BAR_CART: 'panier',
    PROGRESS_BAR_LOGIN: 'connexion',
    PROGRESS_BAR_PAYMENT: 'paiement',
    PROGRESS_BAR_COMPLETE: 'terminer',

    RESET_CATEGORY: 'reset',
    COOKIE_ACCEPT: "J'accepte!",

    EXTERNAL_DOCUMENT_NUMBER: 'Numéro de document externe',
    EXTERNAL_NUMBER_CONFIRM: 'Confirmer',
    EXTERNAL_NUMBER_CHANGE: 'Modifier',
  },
  it: {
    LANG_FULL: 'Italiano',
    DATE_FORMAT: 'd.m.Y',
    HIGHLIGHT_TITLE: 'Benvenuto',
    HIGHLIGHT_DESCRIPTION: ' ',
    NAV_MERCHANDISE: 'Shop',
    NAV_TICKETS: 'Biglietti',
    NAV_GUIDED_TOURS: 'Visite guidate',
    NAV_ALL: 'Tutto',
    NAV_BOOKS: 'Libri',
    NAV_POSTERS: 'Posters',
    NAV_LOGIN: 'Accedi',
    NAV_REGISTER: 'Iscriviti',
    NAV_SETTINGS: 'Impostazioni',
    NAV_LOGOUT: 'Disconnettersi',
    NAV_BACK: 'Indietro',
    NAV_BACK_TO: 'Indietro a',
    NAV_REGISTER_AS_GUEST: 'Indirizzo di fatturazione',
    NAV_DAY_TICKETS: 'Biglietti',
    NAV_MEMCARD: 'Biglietti annuali',

    BREADCRUMBS_MUSEUM_HOME: 'Home',
    BREADCRUMBS_WEBSHOP: 'Webshop',
    GO_HOME: 'Torna allo shop',

    ALL_PRICES_INCLUDE_VAT: 'tutti i prezzi sono comprensivi di IVA',
    ALL_PRICES_INCLUDE_VAT_SHORT: 'incl. la TVA',

    DATE_PICKER_UNAVAILABLE_DAY: 'Giorno non è disponibile',
    DATE_PICKER_SOLDOUT_DAY: 'Il giorno è esaurito',
    TIME_PICKER_SOLDOUT_TIME: 'Il tempo è esaurito',

    TICKETS_PAGE_TITLE: 'Biglietti',
    TICKETS_TIME: "Orari d'apertura",
    TICKETS_BUY_PREFIX: 'Acquistare',
    TICKETS_BUY_SUFFIX: ' ',
    TICKETS_TICKETS: 'Biglietti',
    TICKETS_TICKET: 'Biglietto',
    TICKETS_FROM: 'a partire dal',
    TICKETS_TO: 'a',
    TICKETS_SELECT_A_DATE: 'Si prega di selezionare la data desiderata',
    TICKETS_ADDED_TO_CART: 'Biglietti aggiunti al carrello',
    TICKETS_TOTAL: 'Totale',
    TICKETS_WRONG_FAMILY_TICKET_COUNT:
      'Si prega di selezionare un numero per ogni biglietto parziale',
    TICKETS_MOBILE_TICKETS: 'Mostra biglietti digitali',
    TICKETS_EXPIRED: 'Questo evento è finito!',
    TICKETS_OVERBOOKING_MSG_PART1: 'Lei sta cercando di prenotare',
    TICKETS_OVERBOOKING_MSG_PART2: 'biglietti ma sono soltanto',
    TICKETS_OVERBOOKING_MSG_PART3: 'disponibile',
    TICKETS_TIME_UNAVAILABLE: 'Biglietti esauriti',
    TICKETS_NO_EVENTS: 'Nessun risultato per questa selezione',
    TICKETS_NO_EVENTS_MATCHING_FILTERS: 'Nessun risultato per questa selezione',

    DAY_TICKETS_PAGE_TITLE: 'Biglietti',
    GUIDED_TOURS_PAGE_TITLE: 'Visite guidate',
    GUIDED_TOURS_NO_TOURS: 'Nessun tour per i filtri selezionati',
    GUIDED_TOURS_PRICES_FROM: 'Prezzo da:',

    GUIDED_TOURS_ANY_DATE: 'Data',
    GUIDED_TOURS_TODAY: 'Oggi',
    GUIDED_TOURS_TOMORROW: 'Domani',

    GUIDED_TOUR_SINGLE_PAGE_TITLE: 'Visite guidate',
    GUIDED_TOUR_SELECT_DATE: 'Vogliate selezionare un giorno',
    GUIDED_TOUR_SELECT_TIME: 'Vogliate selezionare un orario',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART1:
      'Questo tour è prenotabile solo per visite guidate con almeno',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_SINGULAR: 'persona',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_PLURAL: 'persone',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART1: 'Lei sta cercando di prenotare',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART2: 'biglietti ma sono soltanto',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART3: 'disponibile',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART1:
      'Per la visita guidata lei sta cercando di prenotare biglietti per',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART2: 'persone, ma ci sono rimasti soltanto',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART3: 'disponibile',
    GUIDED_TOUR_MORE_TICKETS_THAN_TOURS_MSG:
      'Il numero dei biglietti e dei posti per la visita guidata dovrebbero essere uguali',
    GUIDED_TOUR_LESS_TICKETS_THAN_TOURS_MSG:
      'Il numero dei biglietti e dei posti per la visita guidata dovrebbero essere uguali',
    GUIDED_TOUR_PARTICIPANTS: 'Persone',
    GUIDED_TOUR_TOTAL_PRICE: 'Totale',
    GUIDED_TOUR_AVAILABLE_TICKETS_FOR_SELECTED_TIME:
      'Biglietti disponibili per il tempo selezionato',
    GUIDED_TOUR_AVAILABLE_PARTICIPANTS_FOR_SELECTED_TIME:
      'Posti disponibili per la visita guidata nel tempo selezionato',
    GUIDED_TOUR_FILTER_LANGUAGE: 'Lingua',
    GUIDED_TOUR_NOT_AVAILABLE: 'Visita guidata non disponibile',
    GUIDED_TOUR_SOLD_OUT: 'Questa visita guidata è esaurito',
    GUIDED_TOUR_NO_TICKET_AVAILABLE: '',
    GUIDED_TOUR_BUY_TICKETS_PREFIX: 'Visita guidata con',
    GUIDED_TOUR_BUY_TICKETS_SUFFIX: 'Compra biglietti',
    GUIDED_TOUR_BUY: 'Comprare',
    GUIDED_TOUR_ADDED_TO_CART: 'Visita guidata aggiunta al carrello',
    GUIDED_TOUR_VAT: 'IVA',
    GUIDED_TOUR_QUESTIONS: 'Ulteriori informazioni sul gruppo',
    GUIDED_TOUR_NOT_AVAILABLE_THIS_DAY_BUT_AVAILABLE_ON:
      'Il tour non è disponibile per la data selezionata. La prossima data disponibile è',
    GUIDED_TOUR_SHOW_ME: 'mostra',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_1: 'di',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_2: 'tour selezionati. Ospita un massimo di',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_3: 'partecipanti.',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B1: 'visite guidate con un massimo di',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B2: 'partecipanti per visita guidata',

    GUIDED_TOUR_IS_NOT_BOOKABLE: 'attualmente non prenotabile',

    FIELD_IS_REQUIRED: 'Si prega di rispondere a tutti i campi obbligatori',

    AUTH_EMAIL_LABEL: 'E-Mail',
    AUTH_EMAIL_CONFIRM_LABEL: 'Conferma via E-Mail',
    AUTH_EMAILS_DO_NOT_MATCH: 'Gli indirizzi E-Mail non corrispondono',
    AUTH_PASSWORD_LABEL: 'password',
    AUTH_PASSWORD_CONFIRM_LABEL: 'conferma password',
    AUTH_PASSWORDS_DO_NOT_MATCH: 'le password non corrispondono',
    AUTH_COMPANY_LABEL: 'Ditta',
    AUTH_PHONE_LABEL: 'Telefono',
    AUTH_FIRST_NAME_LABEL: 'Nome',
    AUTH_LAST_NAME_LABEL: 'Cognome',
    AUTH_CITY_LABEL: 'Luogo',
    AUTH_ADDRESS_LABEL: 'Indirizzo (Strada, Numero civico)',
    AUTH_COUNTRY_LABEL: 'Paese',
    AUTH_ZIP_LABEL: 'CAP',
    AUTH_FORM_HAS_ERRORS: 'Prego correggere le caselle corrispondenti',
    AUTH_NAME: 'Nome',

    AUTH_BTN_LOGIN: 'Accedi',
    AUTH_BTN_REGISTER: 'Iscriviti',
    AUTH_BTN_SAVE: 'Salvare',
    AUTH_SALUTATION_LABEL: 'Titolo',
    AUTH_ACCOUNT_SETTINGS_TITLE: 'Cambiare E-Mail',
    AUTH_ACCOUNT_CHANGE_PASSWORD: 'Cambiare password',
    AUTH_ACCOUNT_CHANGE_EMAIL: 'E-Mail ändern',
    AUTH_SHIPPING_DETAILS: 'Indirizzo di consegna',
    AUTH_BTN_RECOVER: 'ristabilire',
    AUTH_BTN_REGISTER_GUEST: 'prenotare come ospite',
    AUTH_USER_DETAILS: "specificato dall'utente",
    AUTH_USER_SETTINGS_PAGE_TITLE: "impostazione dell'utente",
    AUTH_FORGOT_PASSWORD: 'Dimenticato la password?',
    AUTH_DONT_HAVE_ACCOUNT_QUESTION: 'Non ha ancora un conto?',
    AUTH_ALREADY_HAVE_ACCOUNT_QUESTION: "Ce l'ha già un conto?",
    AUTH_TRIED_TO_ACCESS_UNAUTHORIZED_PAGE: 'Per questo sito ci vuole un accesso.',
    AUTH_SENT_WRONG_CREDENTIALS: "Nome d'utente/E-Mail o password sbagliato!",
    AUTH_FORGOT_INVALID_EMAIL: 'E-Mail non valido',
    AUTH_LOGGING_IN: 'Registrazione in corso',
    AUTH_LOGIN_SUCCESS: 'Registrazione riuscita',
    AUTH_LOGIN_FAILED: 'Registrazione fallita',
    AUTH_REQUESTING_RESET: 'reimpostare la password',
    AUTH_REQUEST_RESET_SUCCESS: 'cambiamento password riuscito',
    AUTH_REQUEST_RESET_FAIL: 'cambiamento password fallito',
    AUTH_SUCCESS_RESET_TITLE: 'cambiamento password riuscito',
    AUTH_SUCCESS_RESET_MESSAGE: 'Lei riceve un E-Mail con istruzioni',
    AUTH_NEW_PASSWORD_LABEL: 'Nuova password',
    AUTH_RECOVER_ACCOUNT: 'Richiedi password',
    AUTH_SETTINGS_PAGE_TITLE: 'Impostazioni',
    AUTH_LOGIN_PAGE_TITLE: 'accedi',
    AUTH_SETTING_NEW_PASSWORD: 'Nuova password viene salvata...',
    AUTH_SETTING_NEW_PASSWORD_FAILED: 'fallito',
    AUTH_SETTING_NEW_PASSWORD_SUCCESS: 'password salvata',
    AUTH_RECOVER_SUCCESS_PAGE_TITLE: 'conto reimposto',
    AUTH_RECOVER_SUCCESS_TITLE: 'il conto è stato reimposto',
    AUTH_RECOVER_SUCCESS_MESSAGE: 'Lei è automaticamente registrata.',
    AUTH_CURRENT_PASSWORD_LABEL: 'password attuale',
    AUTH_PASSWORD_CHANGE_REQUEST: 'la password viene salvata...',
    AUTH_PASSWORD_CHANGE_SUCCESS: 'password cambiata',
    AUTH_PASSWORD_CHANGE_FAILED: 'fallito',
    AUTH_EMAIL_CHANGE_SUCCESS: '',
    GUEST_REGISTER_PAGE_TITLE: 'Indirizzo di fatturazione',
    MINUTES_SHORT: 'min.',
    CHECKOUT_PAGE_TITLE: 'Checkout',
    CHECKOUT_NO_ITEMS: 'Il carrello per ora è vuoto',
    CHECKOUT_PRODUCT: 'Articoli',
    CHECKOUT_QUANTITY: 'Quantità',
    CHECKOUT_VAT: 'IVA',
    CHECKOUT_PRICE: 'Prezzo',
    CHECKOUT_UNIT_PRICE: 'Prezzo unitario',
    CHECKOUT_TYPE_GUIDED_TOUR: 'Visite guidate',
    CHECKOUT_TYPE_TICKET: 'Ingresso adulti',
    CHECKOUT_TYPE_DAY_TICKET: 'Biglietto',
    CHECKOUT_TOTAL: 'Totale',
    CHECKOUT_DELETE_ARE_YOU_SURE: 'Ma siete sicuri?',
    CHECKOUT_DELETE_YES: 'sì',
    CHECKOUT_DELETE_NO: 'no',
    CHECKOUT_BACK_TO_SHOPPING: 'Continua a fare la spesa',
    CHECKOUT_PAY_WITH: 'Paga con',
    CHECKOUT_CANCEL_ORDER: 'Interrompere',
    CHECKOUT_SUCCESS_PAGE_TITLE: 'Pagamento riuscito',
    CHECKOUT_SUCCESS_MESSAGE: 'Grazie tanto per la sua ordinazione!',
    CHECKOUT_SUCCESS_TICKETS_IN_EMAIL:
      "Riceverai i tuoi biglietti anche via e-mail (con PayPal Express all'indirizzo e-mail registrato con PayPal)!",
    CHECKOUT_FAILED_MESSAGE: 'Pagamento fallito :(',
    CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE: 'Modo di pagare non accettato',
    CHECKOUT_CARD_ACCEPTED: 'Dati di pagamento approvati – Prego concluda sua ordinazione!',
    CHECKOUT_CONFIRM_ORDER: 'Ordinare con obbligo di pagamento',
    CHECKOUT_NOT_LOGGEDIN_MESSAGE: 'Prego si iscriva prima di proseguire con il pagamento',
    CHECKOUT_PAYPAL_EXPRESS_NOT_LOGGEDIN_MESSAGE: 'Per altri metodi di pagamento effettua il login',
    CHECKOUT_I_ACCEPT: 'Io accetto',
    CHECKOUT_THE_TERMS: 'le condizioni generali di contratto',
    CHECKOUT_THE_PRIVACY_POLICY: 'le norme del trattamento dei dati personali',
    CHECKOUT_READ: 'leggere',
    CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE: 'Ordinazione interrotta',
    CHECKOUT_PAYMENT_CANCELED_MESSAGE:
      'Il suo pagamento è stato interrotto. Vuole svuotare il suo carrello?',
    CHECKOUT_CLEAR_CART: 'Cancellare dal carrello',
    CHECKOUT_KEEP_CART: 'Trona al carrello',
    CHECKOUT_CONFIRM_ORDER_PAGE_TITLE: "Controllare l'ordinazione e inviare",
    CHECKOUT_AS_GUEST_MESSAGE: 'Lei può prenotare anche come ospite',
    CHECKOUT_PAYPAL_EXPRESS_AS_GUEST_MESSAGE: 'o fai il check-out come ospite',
    CHECKOUT_AS_GUEST_BUTTON: 'Ordinazione senza conto',
    CHECKOUT_SHIPPING_COSTS: 'spese di spedizione',
    CHECKOUT_SEND_INVOICE: 'Bolletta desiderata',
    CHECKOUT_SEND_INVOICE_INFORMATION: 'Indirizzo di fatturazione',
    CHECKOUT_PAYPAL_EXPRESS_TITLE_SUFFIX: 'Express (adottare i dati)',
    CHECKOUT_PAYPAL_EXPRESS_INFO: 'Usa i suoi dati di Paypal',
    CHECKOUT_NOT_PAYABLE: 'Il carrello non può essere completato!',
    CHECKOUT_CART_GONE:
      "Il tuo carrello non è stato trovato. Il tuo ordine è già stato completato o il processo è andato avanti troppo a lungo. Controlla le tue e-mail. Se non hai ricevuto una conferma d'ordine, riprova più tardi.",
    CHECKOUT_NO_PAY: 'Continua',
    CHECKOUT_NO_SHIPPING_COSTS_FOR_COUNTRY:
      'Non ci sono spese postali per questo paese. Ci contatti per favore.',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_TEXT: 'Qui troverai i dettagli sul tuo',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_LINK: 'diritto di recesso',

    SHOPPING_CART: 'Carrello',

    NAV_VOUCHERS: 'Buoni',

    VOUCHER_PAGE_TITLE: 'Buoni',
    VOUCHER_CATEGORY_PAGE_TITLE: 'Buoni',
    VOUCHER_TOTAL: 'Totale',
    VOUCHER_ADDED_TO_CART: "L'articolo è stato aggiunto al carrello",
    VOUCHER_UNIT_PRICE: 'al Pezzo',
    VOUCHER_QUANTITY: 'Quantità',
    VOUCHER_BUY: 'Acquistare',
    VOUCHER_OUT_OF_STOCK: "L'articolo non è disponibile",
    VOUCHER_EXPIRED: "l'articolo non è più disponibile",
    VOUCHER_BUY_SUFFIX_SINGULAR: ' buono',
    VOUCHER_BUY_SUFFIX_PLURAL: ' buoni',
    VOUCHER_SELECTABLE_PRICE: 'Prezzo liberamente selezionabile',

    VOUCHER: 'Buono',
    VOUCHER_CODE: 'Codice del buono',
    VOUCHER_GET_BUTTON: 'riscuotere',
    VOUCHER_REMAINING_AMOUNT: 'Resto',
    VOUCHER_VALUE: 'Inserisci il valore',

    SHIPPING_TITLE: 'Indirizzo di consegna',
    SHIPPING_CHANGE: 'Modificare',
    SHIPPING_SAME_AS_BILLING: 'Indirizzo di consegna identico',
    SHIPPING_FILL_ALL_FIELDS: "Prego compilare tutte le caselle per l'indirizzo di consegna",
    SHIPPING_WEIGTH_FROM: 'Peso da',

    BILLING_TITLE: 'Indirizzo di fatturazione',
    BILLING_CHANGE: 'Modificare',
    BILLING_SHIPPING_SAVE: 'Salvare',
    MERCHANDISE_PAGE_TITLE: 'Shop',
    MERCHANDISE_CATEGORY_PAGE_TITLE: 'Shop',
    MERCHANDISE_TOTAL: 'Totale',
    MERCHANDISE_ADDED_TO_CART: "L'articolo è stato aggiunto al carrello",
    MERCHANDISE_UNIT_PRICE: 'al Pezzo',
    MERCHANDISE_QUANTITY: 'Quantità',
    MERCHANDISE_BUY: 'Acquistare',
    MERCHANDISE_BUY_SUFFIX_SINGULAR: 'articolo',
    MERCHANDISE_BUY_SUFFIX_PLURAL: 'articoli',
    MERCHANDISE_OUT_OF_STOCK: "L'articolo non è disponibile",
    MERCHANDISE_SOLDOUT: 'esaurito',

    ANNUAL_TICKETS_CARD: 'Carta',
    ANNUAL_TICKETS_NAME: 'Titolo, Nome, Cognome',
    ANNUAL_TICKETS_SENT_TO_CUSTOM_ADDRESS: 'Invia a un indirizzo personalizzato',
    ANNUAL_TICKETS_ADDRESS: 'Indirizzo',

    USER_SETTINGS_SAVED: 'Impostazioni salvate',
    USER_LOGIN_PAGE_TITLE: 'Iscriversi',
    USER_REGISTER_PAGE_TITLE: 'Registrarsi',
    USER_FORGOT_PAGE_TITLE: "Dimenticato i dati d'accesso",
    USER_RECOVER_PAGE_TITLE: 'Reimpostare il conto',
    USER_FORGOT_SUCCESS_PAGE_TITLE: 'Reimpostazione della password riuscita',
    PRODUCT_PAGE_TITLE: 'Articoli',
    PAGE_NOT_FOUND_TITLE: 'Pagina non trovata',
    PAGE_NOT_FOUND_MESSAGE: 'Il sito non è potuto essere trovato',
    USER_REGISTER_SUCCESS_PAGE_TITLE: 'Iscrizione riuscita',
    REGISTRATION_SUCCESS_TITLE: 'Iscrizione accettata',
    REGISTRATION_SUCCESS_TEXT: 'Il suo conto è stato creato',
    FORGOT_PASSWORD_PAGE_TITLE: 'Password dimenticata',
    RECOVER_PAGE_TITLE: 'Reimpostare il conto',

    FOOTER_IMPRINT_LINK: 'Impronta',
    FOOTER_TERMS_LINK_GTC: 'CG',
    FOOTER_TERMS_LINK_DATA_PROTECTION: 'Norma sulla protezione dei dati',
    FOOTER_SHIPPING: 'Spese di spedizione',

    INTERNAL_SERVER_ERROR_MESSAGE_PREFIX:
      'Un errore inaspettato è accaduto. Per ulteriori sostenimenti ci può contattare via',
    INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX: '.',
    PAYMENT_METHOD_CHOICE: 'Prego scegliere',
    REQUIRED_FIELDS: 'Indicazioni obbligatorie',
    SALUTATION_PLACEHOLDER: 'Scegli per favore',
    PROGRESS_BAR_CART: 'Carrello',
    PROGRESS_BAR_LOGIN: 'accedere',
    PROGRESS_BAR_PAYMENT: 'pagamento',
    PROGRESS_BAR_COMPLETE: 'conclusione',

    RESET_CATEGORY: 'reset',
    COOKIE_ACCEPT: "Sono d'accordo!",

    EXTERNAL_DOCUMENT_NUMBER: 'Numero di documento esterno',
    EXTERNAL_NUMBER_CONFIRM: 'Confermare',
    EXTERNAL_NUMBER_CHANGE: 'Modificare',
  },
  nl: {
    MUSEUM: ' ',
    LANG_FULL: 'Nederlands',
    DATE_FORMAT: 'd.m.Y',
    HIGHLIGHT_TITLE: 'Welkom',
    HIGHLIGHT_DESCRIPTION: ' ',
    NAV_MERCHANDISE: 'Shop',
    NAV_TICKETS: 'Tickets',
    NAV_GUIDED_TOURS: 'Rondleidingen',
    NAV_ALL: 'Alle',
    NAV_BOOKS: 'Boeken',
    NAV_POSTERS: 'Poster',
    NAV_LOGIN: 'Inloggen',
    NAV_REGISTER: 'Registeren',
    NAV_SETTINGS: 'Instellingen',
    NAV_LOGOUT: 'Uitloggen',
    NAV_BACK: 'Terug',
    NAV_BACK_TO: 'Terug naar',
    NAV_REGISTER_AS_GUEST: 'Betalingsinformatie',
    NAV_DAY_TICKETS: 'Tickets',
    NAV_MEMCARD: 'Jaarkaarten',
    BREADCRUMBS_MUSEUM_HOME: 'Home',
    BREADCRUMBS_WEBSHOP: 'Webshop',
    GO_HOME: 'Terug naar shop',
    ALL_PRICES_INCLUDE_VAT: 'alle prijzen zijn inclusief BTW',
    ALL_PRICES_INCLUDE_VAT_SHORT: 'incl. BTW',

    DATE_PICKER_UNAVAILABLE_DAY: 'Dag is niet beschikbaar',
    DATE_PICKER_SOLDOUT_DAY: 'Dag is uitverkocht',
    TIME_PICKER_SOLDOUT_TIME: 'De tijd is uitverkocht',

    TICKETS_PAGE_TITLE: 'Tickets',
    TICKETS_TIME: 'Tijd',
    TICKETS_BUY_PREFIX: 'Kopen',
    TICKETS_BUY_SUFFIX: ' ',
    TICKETS_TICKETS: 'Tickets',
    TICKETS_TICKET: 'Ticket',
    TICKETS_FROM: 'van',
    TICKETS_TO: 'naar',
    TICKETS_SELECT_A_DATE: 'Selecteer a.u.b. uw gewenste datum',
    TICKETS_ADDED_TO_CART: 'ticket is toegevoegd aan je winkelwagentje',
    TICKETS_TOTAL: 'Totaal',
    TICKETS_WRONG_FAMILY_TICKET_COUNT: 'Selecteer een hoeveelheid voor elk ticket type',
    TICKETS_MOBILE_TICKETS: 'Mobiel ticket tonen',
    TICKETS_EXPIRED: 'Dit evenement is afgelopen!',
    TICKETS_OVERBOOKING_MSG_PART1: 'Je probeert te boeken',
    TICKETS_OVERBOOKING_MSG_PART2: 'tickets maar er zijn alleen',
    TICKETS_OVERBOOKING_MSG_PART3: 'beschikbaar',
    TICKETS_TIME_UNAVAILABLE: 'Tickets zijn uitverkocht',
    TICKETS_NO_EVENTS: 'Geen resultaten voor deze selectie',
    TICKETS_NO_EVENTS_MATCHING_FILTERS: 'Geen resultaten voor deze selectie',

    DAY_TICKETS_PAGE_TITLE: 'Tickets',
    GUIDED_TOURS_PAGE_TITLE: 'Rondleidingen',
    GUIDED_TOURS_NO_TOURS: 'Geen tours voor de geselecteerde filters',
    GUIDED_TOURS_PRICES_FROM: 'Prijs van:',

    GUIDED_TOURS_ANY_DATE: 'Datum',
    GUIDED_TOURS_TODAY: 'Vandaag',
    GUIDED_TOURS_TOMORROW: 'Morgen',

    GUIDED_TOUR_SINGLE_PAGE_TITLE: 'Rondleidingen',
    GUIDED_TOUR_SELECT_DATE: 'Kies een datum',
    GUIDED_TOUR_SELECT_TIME: 'Kies een tijd',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART1:
      'Deze rondleiding is alleen beschikbaar voor groepen van ten minste ',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_SINGULAR: 'persoon',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_PLURAL: 'persoon',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART1: 'Je probeert te boeken ',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART2: 'tickets maar er zijn alleen',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART3: 'beschikbaar',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART1: 'Je probeert te boeken voor',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART2: 'deelnemers maar alleen',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART3: 'zijn beschikbaar',
    GUIDED_TOUR_MORE_TICKETS_THAN_TOURS_MSG: 'Het aantal tickets en deelnemers moet gelijk zijn',
    GUIDED_TOUR_LESS_TICKETS_THAN_TOURS_MSG: 'Het aantal tickets en deelnemers moet gelijk zijn',
    GUIDED_TOUR_PARTICIPANTS: 'personen',
    GUIDED_TOUR_TOTAL_PRICE: 'Totaal',
    GUIDED_TOUR_AVAILABLE_TICKETS_FOR_SELECTED_TIME: 'Beschikbare tickets voor geselecteerde tijd',
    GUIDED_TOUR_AVAILABLE_PARTICIPANTS_FOR_SELECTED_TIME:
      'Beschikbaar aantal deelnemers voor geselecteerde tijd',
    GUIDED_TOUR_BUY_TICKETS_PREFIX: 'Koop',
    GUIDED_TOUR_BUY_TICKETS_SUFFIX: 'tickets',
    GUIDED_TOUR_BUY: 'Koop',
    GUIDED_TOUR_ADDED_TO_CART: 'Rondleiding is toegevoegd aan je winkelwagentje',
    GUIDED_TOUR_FILTER_LANGUAGE: 'Taal filter',
    GUIDED_TOUR_NOT_AVAILABLE: 'Deze rondleiding is momenteel niet beschikbaar',
    GUIDED_TOUR_SOLD_OUT: 'Deze rondleiding is uitverkocht',
    GUIDED_TOUR_NO_TICKET_AVAILABLE: 'Er zijn geen tickets beschikbaar voor deze rondleiding',
    GUIDED_TOUR_VAT: 'BTW',
    GUIDED_TOUR_QUESTIONS: 'Aanvullende informatie over de groep',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_1: 'van de',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_2: 'tours geselecteerd. Biedt plaats aan maximaal',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_3: 'deelnemers.',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B1: 'rondleidingen met max.',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B2: 'deelnemers per rondleiding',
    GUIDED_TOUR_NOT_AVAILABLE_THIS_DAY_BUT_AVAILABLE_ON:
      'De tour is niet beschikbaar voor de geselecteerde datum. De volgende beschikbare datum is',
    GUIDED_TOUR_SHOW_ME: 'show',
    GUIDED_TOUR_IS_NOT_BOOKABLE: 'momenteel niet boekbaar',

    FIELD_IS_REQUIRED: 'Gelieve alle verplichte velden in te vullen',
    AUTH_SALUTATION_LABEL: 'Titel',
    AUTH_EMAIL_LABEL: 'Email',
    AUTH_EMAIL_CONFIRM_LABEL: 'E-mail bevestigen',
    AUTH_EMAILS_DO_NOT_MATCH: 'E-mails komen niet overeen',
    AUTH_PASSWORD_LABEL: 'Wachtwoord',
    AUTH_PASSWORD_CONFIRM_LABEL: 'Wachtwoord bevestigen',
    AUTH_PASSWORDS_DO_NOT_MATCH: 'Wachtwoorden komen niet overeen',
    AUTH_COMPANY_LABEL: 'Bedrijf',
    AUTH_PHONE_LABEL: 'Telefoon',
    AUTH_FIRST_NAME_LABEL: 'Voornaam',
    AUTH_LAST_NAME_LABEL: 'Naam',
    AUTH_CITY_LABEL: 'Stad',
    AUTH_ADDRESS_LABEL: 'Adres (Straat, Nummer)',
    AUTH_COUNTRY_LABEL: 'Land',
    AUTH_ZIP_LABEL: 'Postcode',
    AUTH_FORM_HAS_ERRORS: 'Gelieve de fouten in het formulier te corrigeren',
    AUTH_NAME: 'Naam',
    AUTH_BTN_LOGIN: 'Login',
    AUTH_BTN_REGISTER: 'Registreren',
    AUTH_BTN_RECOVER: 'Herstel',
    AUTH_BTN_SAVE: 'Opslaan',
    AUTH_FORGOT_PASSWORD: 'Ben je je paswoord vergeten?',
    AUTH_DONT_HAVE_ACCOUNT_QUESTION: 'Ik heb nog geen account?',
    AUTH_ALREADY_HAVE_ACCOUNT_QUESTION: 'Ik heb al een account?',
    AUTH_TRIED_TO_ACCESS_UNAUTHORIZED_PAGE:
      'Voor de pagina die je probeerde te openen, moet je inloggen',
    AUTH_SENT_WRONG_CREDENTIALS: 'E-mail of wachtwoord zijn niet correct',
    AUTH_FORGOT_INVALID_EMAIL: 'Kan deze e-mail niet herstellen',
    AUTH_LOGGING_IN: 'Aanmelden',
    AUTH_LOGIN_SUCCESS: 'Login succesvol',
    AUTH_LOGIN_FAILED: 'Inloggen mislukt',
    AUTH_REQUESTING_RESET: 'Reset aanvragen',
    AUTH_REQUEST_RESET_SUCCESS: 'Wachtwoord resetten succesvol',
    AUTH_REQUEST_RESET_FAIL: 'Wachtwoord reset mislukt',
    AUTH_SUCCESS_RESET_TITLE: 'Wachtwoord resetten succesvol',
    AUTH_SUCCESS_RESET_MESSAGE:
      'Je zou binnenkort een e-mail ontvangen met instructies over hoe je verder moet gaan.',
    AUTH_NEW_PASSWORD_LABEL: 'Nieuw wachtwoord',
    AUTH_RECOVER_ACCOUNT: 'Account herstellen',
    AUTH_SETTINGS_PAGE_TITLE: 'Gebruikersinstellingen',
    AUTH_LOGIN_PAGE_TITLE: 'Login',
    AUTH_SETTING_NEW_PASSWORD: 'Nieuw wachtwoord opslaan…',
    AUTH_SETTING_NEW_PASSWORD_FAILED: 'Wachtwoord opslaan is mislukt',
    AUTH_SETTING_NEW_PASSWORD_SUCCESS: 'Wachtwoord opgeslagen',
    AUTH_RECOVER_SUCCESS_PAGE_TITLE: 'Account hersteld',
    AUTH_RECOVER_SUCCESS_TITLE: 'Account hersteld',
    AUTH_RECOVER_SUCCESS_MESSAGE: 'U bent automatisch ingelogd.',
    AUTH_CURRENT_PASSWORD_LABEL: 'Huidig wachtwoord',
    AUTH_PASSWORD_CHANGE_REQUEST: 'Wachtwoord veranderen...',
    AUTH_PASSWORD_CHANGE_SUCCESS: 'Wachtwoord veranderd',
    AUTH_PASSWORD_CHANGE_FAILED: 'Wachtwoordwijziging mislukt',
    AUTH_EMAIL_CHANGE_SUCCESS: '',
    AUTH_ACCOUNT_SETTINGS_TITLE: 'Account Instellingen',
    AUTH_ACCOUNT_CHANGE_PASSWORD: 'Wachtwoord wijzigen',
    AUTH_ACCOUNT_CHANGE_EMAIL: 'E-Mail wijzigen',
    AUTH_SHIPPING_DETAILS: 'Bezorgadres',
    AUTH_BTN_REGISTER_GUEST: 'Gastregistratie',
    AUTH_USER_DETAILS: 'Gebruikers informatie',
    AUTH_USER_SETTINGS_PAGE_TITLE: 'Gebruikersinstellingen',
    GUEST_REGISTER_PAGE_TITLE: 'Gastregistratie',
    MINUTES_SHORT: 'min.',
    CHECKOUT_PAGE_TITLE: 'Bestellen',
    CHECKOUT_NO_ITEMS: 'Uw winkelmandje is nog leeg',
    CHECKOUT_PRODUCT: 'Product',
    CHECKOUT_QUANTITY: 'Aantal',
    CHECKOUT_VAT: 'BTW',
    CHECKOUT_PRICE: 'Prijs',
    CHECKOUT_UNIT_PRICE: 'Prijs per stuk',
    CHECKOUT_TYPE_GUIDED_TOUR: 'Rondleiding',
    CHECKOUT_TYPE_TICKET: 'Entree ticket',
    CHECKOUT_TYPE_DAY_TICKET: 'Ticket',
    CHECKOUT_TOTAL: 'Totaal',
    CHECKOUT_DELETE_ARE_YOU_SURE: 'Ben je zeker?',
    CHECKOUT_DELETE_YES: 'Ja',
    CHECKOUT_DELETE_NO: 'Nee',
    CHECKOUT_BACK_TO_SHOPPING: 'Terug naar winkelen',
    CHECKOUT_PAY_WITH: 'Betalen met',
    CHECKOUT_CANCEL_ORDER: 'Bestelling annuleren',
    CHECKOUT_SUCCESS_PAGE_TITLE: 'Betaling succesvol',
    CHECKOUT_SUCCESS_MESSAGE: 'Dank u voor je bestelling!',
    CHECKOUT_SUCCESS_TICKETS_IN_EMAIL:
      'Je ontvangt je tickets ook per e-mail (met PayPal Express naar het e-mailadres dat bij PayPal is geregistreerd)!',
    CHECKOUT_FAILED_MESSAGE: 'Jouw betaling is niet succesvol afgerond. Probeer het nog eens.',
    CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE: 'Betaling aangenomen',
    CHECKOUT_CARD_ACCEPTED: 'Betalingswijze is geaccepteerd - Bevestig de bestelling!',
    CHECKOUT_CONFIRM_ORDER: 'Bevestig de bestelling',
    CHECKOUT_NOT_LOGGEDIN_MESSAGE: 'Je moet inloggen voordat je tot betaling overgaat',
    CHECKOUT_PAYPAL_EXPRESS_NOT_LOGGEDIN_MESSAGE: 'Voor andere betaalmethoden gelieve in te loggen',

    CHECKOUT_I_ACCEPT: 'Ik accepteer',
    CHECKOUT_THE_TERMS: 'de algemene voorwaarden',
    CHECKOUT_THE_PRIVACY_POLICY: 'Privacybeleid',
    CHECKOUT_READ: 'Lees',
    CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE: 'Betaling geannuleerd',
    CHECKOUT_PAYMENT_CANCELED_MESSAGE:
      'Je betaling is geannuleerd. Wilt je de winkelwagen leegmaken?',
    CHECKOUT_CLEAR_CART: 'Maak winkelwagen leeg',
    CHECKOUT_KEEP_CART: 'Winkelwagen houden',
    CHECKOUT_CONFIRM_ORDER_PAGE_TITLE: 'Bevestig uw bestelling',
    CHECKOUT_AS_GUEST_MESSAGE: 'Of je kunt als gast afrekenen voor een enkele aankoop',
    CHECKOUT_PAYPAL_EXPRESS_AS_GUEST_MESSAGE: 'of uitchecken als gast',
    CHECKOUT_AS_GUEST_BUTTON: 'Verder als gast',
    CHECKOUT_SHIPPING_COSTS: 'Verzendkosten',
    CHECKOUT_SEND_INVOICE: 'Ik heb een factuur nodig',
    CHECKOUT_SEND_INVOICE_INFORMATION: 'Betalingsinformatie',
    CHECKOUT_PAYPAL_EXPRESS_TITLE_SUFFIX: 'Express',
    CHECKOUT_PAYPAL_EXPRESS_INFO: 'Gebruik uw paypal gegevens',
    CHECKOUT_CART_GONE:
      'Je karretje is niet gevonden. Misschien was je bestelling al betaald of duurde het te lang om de bestelling af te ronden. Controleer je e-mail voor een orderbevestiging of probeer de artikelen opnieuw te kopen.',
    CHECKOUT_NOT_PAYABLE: 'Het winkelproces kan niet worden voltooid!',
    CHECKOUT_NO_PAY: 'Ga verder',
    SHOPPING_CART: 'Winkelwagen',
    DIRECT_DEBIT: 'Automatische incasso',
    CHECKOUT_CANCEL: 'Annuleer',
    CHECKOUT_NO_SHIPPING_COSTS_FOR_COUNTRY:
      'Er worden geen verzendkosten voor dit land opgeslagen. Neem contact met ons op.',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_TEXT: 'Hier vindt u details over uw',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_LINK: 'herroepingsrecht',

    NAV_VOUCHERS: 'Vouchers',
    VOUCHER_PAGE_TITLE: 'Vouchers',
    VOUCHER_CATEGORY_PAGE_TITLE: 'Shop',
    VOUCHER_TOTAL: 'Totaal',
    VOUCHER_UNIT_PRICE: 'Per stuk',
    VOUCHER_ADDED_TO_CART: 'Voucher toegevoegd aan winkelwagen',
    VOUCHER_QUANTITY: 'Aantal',
    VOUCHER_BUY: 'Koop',
    VOUCHER_OUT_OF_STOCK: 'Item is momenteel niet op voorraad',
    VOUCHER_EXPIRED: 'Item is niet langer beschikbaar',
    VOUCHER_BUY_SUFFIX_SINGULAR: ' voucher',
    VOUCHER_BUY_SUFFIX_PLURAL: ' vouchers',
    VOUCHER: 'Voucher',
    VOUCHER_CODE: 'Voucher code',
    VOUCHER_GET_BUTTON: 'Verzilveren',
    VOUCHER_REMAINING_AMOUNT: 'Resterend bedrag',
    VOUCHER_SELECTABLE_PRICE: 'Prijs vrij selecteerbaar',
    VOUCHER_VALUE: 'Waarde invoeren',
    SHIPPING_TITLE: 'Bezorgadres',
    SHIPPING_CHANGE: 'Verander',
    SHIPPING_SAME_AS_BILLING: 'Hetzelfde als factuuradres',
    SHIPPING_FILL_ALL_FIELDS: 'Vul alle velden in voor Verzendadres',
    SHIPPING_WEIGTH_FROM: 'Gewicht van',
    BILLING_TITLE: 'Factuuradres',
    BILLING_CHANGE: 'Verander',
    BILLING_SHIPPING_SAVE: 'Opslaan',
    MERCHANDISE_PAGE_TITLE: 'Shop',
    MERCHANDISE_CATEGORY_PAGE_TITLE: 'Shop',
    MERCHANDISE_TOTAL: 'Totaal',
    MERCHANDISE_UNIT_PRICE: 'Per stuk',
    MERCHANDISE_ADDED_TO_CART: 'Product toegevoegd aan winkelwagen',
    MERCHANDISE_QUANTITY: 'Aantal',
    MERCHANDISE_BUY: 'Koop',
    MERCHANDISE_BUY_SUFFIX_SINGULAR: 'artikel',
    MERCHANDISE_BUY_SUFFIX_PLURAL: 'artikelen',
    MERCHANDISE_OUT_OF_STOCK: 'Artikel is momenteel niet op voorraad',
    MERCHANDISE_SOLDOUT: 'uitverkocht',

    ANNUAL_TICKETS_CARD: 'Kaart',
    ANNUAL_TICKETS_NAME: 'Titel, Voornaam, Achternaam',
    ANNUAL_TICKETS_SENT_TO_CUSTOM_ADDRESS: 'Verzenden naar aangepast adres',
    ANNUAL_TICKETS_ADDRESS: 'Adres',

    USER_SETTINGS_SAVED: 'Instellingen opgeslagen',
    USER_LOGIN_PAGE_TITLE: 'Login',
    USER_REGISTER_PAGE_TITLE: 'Registreren',
    USER_FORGOT_PAGE_TITLE: 'Geloofsbrieven vergeten',
    USER_RECOVER_PAGE_TITLE: 'Herstel account',
    USER_FORGOT_SUCCESS_PAGE_TITLE: 'Wachtwoord reset success',
    PRODUCT_PAGE_TITLE: 'Product',
    PAGE_NOT_FOUND_TITLE: 'Pagina niet gevonden',
    PAGE_NOT_FOUND_MESSAGE: 'De pagina die je zoekt werd niet gevonden',
    USER_REGISTER_SUCCESS_PAGE_TITLE: 'Gebruiker succesvol geregistreerd',
    REGISTRATION_SUCCESS_TITLE: 'Registratie geaccepteerd',
    REGISTRATION_SUCCESS_TEXT: 'Je account is nu aangemaakt',
    FORGOT_PASSWORD_PAGE_TITLE: 'Wachtwoord vergeten',
    RECOVER_PAGE_TITLE: 'Herstel account',
    FOOTER_IMPRINT_LINK: 'Afdruk',
    FOOTER_TERMS_LINK_GTC: 'GTC',
    FOOTER_TERMS_LINK_DATA_PROTECTION: 'Privacybeleid',
    FOOTER_SHIPPING: 'Verzendkosten',
    INTERNAL_SERVER_ERROR_MESSAGE_PREFIX:
      'Er is een onverwachte fout opgetreden. Dit mag nooit gebeuren. Neem contact met ons op via',
    INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX: 'als je nog meer hulp nodig hebt.',
    PAYMENT_METHOD_CHOICE: 'selecteer alstublieft',
    REQUIRED_FIELDS: 'verplichte velden',
    SALUTATION_PLACEHOLDER: 'selecteer alstublieft',
    PROGRESS_BAR_CART: 'Winkelwagen',
    PROGRESS_BAR_LOGIN: 'aanmelden',
    PROGRESS_BAR_PAYMENT: 'betaling',
    PROGRESS_BAR_COMPLETE: 'compleet',
    RESET_CATEGORY: 'reset',
    SELECT_INPUT_PLACEHOLDER: 'selecteer alstublieft…',
    COOKIE_ACCEPT: 'Ik ben het eens!',

    EXTERNAL_DOCUMENT_NUMBER: 'Extern documentnummer!',
    EXTERNAL_NUMBER_CONFIRM: 'Bevestig',
    EXTERNAL_NUMBER_CHANGE: 'Wijzig',
  },
  es: {
    MUSEUM: ' ',
    LANG_FULL: 'Español',
    DATE_FORMAT: 'd.m.Y',
    HIGHLIGHT_TITLE: 'Bienvenido',
    HIGHLIGHT_DESCRIPTION: ' ',
    NAV_MERCHANDISE: 'Tienda',
    NAV_TICKETS: 'Entradas',
    NAV_GUIDED_TOURS: 'Visitas Guiadas',
    NAV_ALL: 'Todo',
    NAV_BOOKS: 'Libros',
    NAV_POSTERS: 'Pósteres',
    NAV_LOGIN: 'Iniciar sesión',
    NAV_REGISTER: 'Registro',
    NAV_SETTINGS: 'Configuraciones',
    NAV_LOGOUT: 'Cerrar sesión',
    NAV_BACK: 'volver',
    NAV_BACK_TO: 'volver a',
    NAV_REGISTER_AS_GUEST: 'Información sobre facturación',
    NAV_DAY_TICKETS: 'Entradas',
    NAV_MEMCARD: 'Entradas anuales',
    BREADCRUMBS_MUSEUM_HOME: 'Inicio',
    BREADCRUMBS_WEBSHOP: 'Tienda Web',
    GO_HOME: 'Volver a la tienda',
    ALL_PRICES_INCLUDE_VAT: 'Todo precio incluye impuesto de ventas',
    ALL_PRICES_INCLUDE_VAT_SHORT: 'Impuesto de venta incluido',

    DATE_PICKER_UNAVAILABLE_DAY: 'El día no está disponible',
    DATE_PICKER_SOLDOUT_DAY: 'El día está agotado',
    TIME_PICKER_SOLDOUT_TIME: 'El tiempo está agotado',

    TICKETS_PAGE_TITLE: 'Entradas',
    TICKETS_TIME: 'Tiempo',
    TICKETS_BUY_PREFIX: 'Compra',
    TICKETS_BUY_SUFFIX: ' ',
    TICKETS_TICKETS: 'Entradas',

    TICKETS_TICKET: 'Entrada',

    TICKETS_FROM: 'desde',

    TICKETS_TO: 'hasta',
    TICKETS_SELECT_A_DATE: 'Por favor seleccione la fecha deseada',
    TICKETS_ADDED_TO_CART: 'Entradas añadidas a la cesta de compras.',
    TICKETS_TOTAL: 'Total',
    TICKETS_WRONG_FAMILY_TICKET_COUNT:
      'Por favor, seleccione la cantidad deseada para cada componente de cada entrada.',
    TICKETS_MOBILE_TICKETS: 'Muestre entrada móvil',
    TICKETS_EXPIRED: '!Este evento ya caducó!',
    TICKETS_OVERBOOKING_MSG_PART1: 'Usted está intentando de reservar entradas,',
    TICKETS_OVERBOOKING_MSG_PART2: 'pero únicamente ',
    TICKETS_OVERBOOKING_MSG_PART3: 'entradas están disponibles',
    TICKETS_TIME_UNAVAILABLE: 'Entradas agotadas',
    TICKETS_NO_EVENTS: 'No hay resultados para esta selección',
    TICKETS_NO_EVENTS_MATCHING_FILTERS: 'No hay resultados para esta selección',

    DAY_TICKETS_PAGE_TITLE: 'Entradas',
    GUIDED_TOURS_PAGE_TITLE: 'Visitas Guiadas',
    GUIDED_TOURS_NO_TOURS: 'No hay tours disponibles para los filtros seleccionados',
    GUIDED_TOURS_PRICES_FROM: 'Precios desde: ',

    GUIDED_TOURS_ANY_DATE: 'Fecha',
    GUIDED_TOURS_TODAY: 'Hoy',
    GUIDED_TOURS_TOMORROW: 'Mañana',

    GUIDED_TOUR_SINGLE_PAGE_TITLE: 'Visitas Guiadas',
    GUIDED_TOUR_SELECT_DATE: 'Seleccione una fecha',
    GUIDED_TOUR_SELECT_TIME: 'Seleccione una hora',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART1:
      'Esta visita guiada sólo está disponible para grupos de un mínimo de ',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_SINGULAR: 'persona participante',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_PLURAL: 'personas participantes',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART1: 'Usted está intentando de reservar entradas',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART2: 'pero sólo',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART3: 'entradas están disponibles disponibles',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART1:
      'Usted está intentando de reservar entradas para',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART2: 'participantes, pero únicamente',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART3: 'entradas están disponibles',
    GUIDED_TOUR_MORE_TICKETS_THAN_TOURS_MSG:
      'El número de entradas y el número de personas participantes deberían coincidir.',
    GUIDED_TOUR_LESS_TICKETS_THAN_TOURS_MSG:
      'El número de entradas y el número de personas participantes deberían coincidir.',
    GUIDED_TOUR_PARTICIPANTS: 'Personas participantes',
    GUIDED_TOUR_TOTAL_PRICE: 'Total',
    GUIDED_TOUR_AVAILABLE_TICKETS_FOR_SELECTED_TIME:
      'Entradas disponibles para el horario seleccionado',
    GUIDED_TOUR_AVAILABLE_PARTICIPANTS_FOR_SELECTED_TIME:
      'Número disponible de personas participantes para el horario seleccionado.',
    GUIDED_TOUR_BUY_TICKETS_PREFIX: 'Comprar',
    GUIDED_TOUR_BUY_TICKETS_SUFFIX: 'Entradas',
    GUIDED_TOUR_BUY: 'Comprar',
    GUIDED_TOUR_ADDED_TO_CART: 'Visita Guiada añadida a la cesta de compras',
    GUIDED_TOUR_FILTER_LANGUAGE: 'Filtro de idioma',
    GUIDED_TOUR_NOT_AVAILABLE: 'Actualmente esta visita guiada no está disponible.',
    GUIDED_TOUR_SOLD_OUT: 'Esta visita guiada está agotada',
    GUIDED_TOUR_NO_TICKET_AVAILABLE: 'No hay entradas disponibles para esta visita guiada.',
    GUIDED_TOUR_VAT: 'Impuesto sobre las ventas.',
    GUIDED_TOUR_QUESTIONS: 'Información adicional acerca del grupo',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_1: 'de',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_2: 'tours seleccionados. Acomoda un máximo de',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_3: 'participantes.',

    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B1: 'visitas guiadas con un máximo de',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B2: 'participantes por visita guiada',
    GUIDED_TOUR_NOT_AVAILABLE_THIS_DAY_BUT_AVAILABLE_ON:
      'El tour no está disponible para la fecha seleccionada. La próxima fecha disponible es el',
    GUIDED_TOUR_SHOW_ME: 'mostrar',
    GUIDED_TOUR_IS_NOT_BOOKABLE: 'actualmente no se puede reservar',

    FIELD_IS_REQUIRED: 'Por favor, rellene todos los espacios obligatorios.',
    AUTH_SALUTATION_LABEL: 'Título',
    AUTH_EMAIL_LABEL: 'Correo electrónico',
    AUTH_EMAIL_CONFIRM_LABEL: 'Confirme su correo electrónico.',
    AUTH_EMAILS_DO_NOT_MATCH: 'Los correos electrónicos no coinciden',
    AUTH_PASSWORD_LABEL: 'Contraseña',
    AUTH_PASSWORD_CONFIRM_LABEL: 'Confirme su contraseña.',
    AUTH_PASSWORDS_DO_NOT_MATCH: 'Las contraseñas no coinciden.',
    AUTH_COMPANY_LABEL: 'Empresa',
    AUTH_PHONE_LABEL: 'Teléfono',
    AUTH_FIRST_NAME_LABEL: 'Nombre',
    AUTH_LAST_NAME_LABEL: 'Apellido',
    AUTH_CITY_LABEL: 'Ciudad',
    AUTH_ADDRESS_LABEL: 'Dirección (calle, número de la casa)',
    AUTH_COUNTRY_LABEL: 'País',
    AUTH_ZIP_LABEL: 'Código postal',
    AUTH_FORM_HAS_ERRORS: 'Por favor, corrija los errores en el formulario',
    AUTH_NAME: 'Nombre',
    AUTH_BTN_LOGIN: 'Iniciar sesión',
    AUTH_BTN_REGISTER: 'Registrarse',
    AUTH_BTN_RECOVER: 'Recuperar',
    AUTH_BTN_SAVE: 'Guardar',
    AUTH_FORGOT_PASSWORD: '?Usted ha olvidado su contraseña?',
    AUTH_DONT_HAVE_ACCOUNT_QUESTION: '?No tengo cuenta todavía?',
    AUTH_ALREADY_HAVE_ACCOUNT_QUESTION: '?Ya tengo una cuenta?',
    AUTH_TRIED_TO_ACCESS_UNAUTHORIZED_PAGE:
      'La página que usted estaba intentando de abrir requiere contraseña.',
    AUTH_SENT_WRONG_CREDENTIALS: 'El correo electrónico o la contraseña no son correctos.',
    AUTH_FORGOT_INVALID_EMAIL: 'No es posible recuperar este correo electrónico.',
    AUTH_LOGGING_IN: 'Iniciar sesión',
    AUTH_LOGIN_SUCCESS: 'Inicio de sesión correcto',
    AUTH_LOGIN_FAILED: 'Error de inicio de sesión',
    AUTH_REQUESTING_RESET: 'Se requiere restablecimiento de contraseña de la cuenta.',
    AUTH_REQUEST_RESET_SUCCESS: 'Restablecimiento de contraseña exitoso.',
    AUTH_REQUEST_RESET_FAIL: 'El restablecimiento de contraseña no se ha realizado correctamente.',
    AUTH_SUCCESS_RESET_TITLE: 'El restablecimiento de contraseña se ha realizado correctamente.',
    AUTH_SUCCESS_RESET_MESSAGE:
      'Dentro de poco usted debería recibir un correo electrónico con instrucciones     para poder continuar.',
    AUTH_NEW_PASSWORD_LABEL: 'Contraseña nueva',
    AUTH_RECOVER_ACCOUNT: 'Recupere su cuenta.',
    AUTH_SETTINGS_PAGE_TITLE: 'Configuraciones del usuario',
    AUTH_LOGIN_PAGE_TITLE: 'Iniciar la sesión',
    AUTH_SETTING_NEW_PASSWORD: 'Guardar contraseña nueva',
    AUTH_SETTING_NEW_PASSWORD_FAILED: 'Error',
    AUTH_SETTING_NEW_PASSWORD_SUCCESS: 'Contraseña guardada.',
    AUTH_RECOVER_SUCCESS_PAGE_TITLE: 'Cuenta recuperada.',
    AUTH_RECOVER_SUCCESS_TITLE: 'Su cuenta ha sido recuperada.',
    AUTH_RECOVER_SUCCESS_MESSAGE: 'Usted ha sido ingresado a su cuenta automáticamente.',
    AUTH_CURRENT_PASSWORD_LABEL: 'Contraseña actual',
    AUTH_PASSWORD_CHANGE_REQUEST: 'Cambiando contraseña…',
    AUTH_PASSWORD_CHANGE_SUCCESS: 'Contraseña nueva',
    AUTH_PASSWORD_CHANGE_FAILED: 'Cambio de contraseña ha fallado.',
    AUTH_EMAIL_CHANGE_SUCCESS: 'Cambio de contraseña exitoso',
    AUTH_ACCOUNT_SETTINGS_TITLE: 'Configuraciones de cuenta',
    AUTH_ACCOUNT_CHANGE_PASSWORD: 'Cambiar contraseña',
    AUTH_ACCOUNT_CHANGE_EMAIL: 'Cambiar correo electrónico',
    AUTH_SHIPPING_DETAILS: 'Dirección para la entrega',
    AUTH_BTN_REGISTER_GUEST: 'Regístrese como invitado',
    AUTH_USER_DETAILS: 'Detalles del usuario',
    AUTH_USER_SETTINGS_PAGE_TITLE: 'Configuraciones del usuario',
    GUEST_REGISTER_PAGE_TITLE: 'Registro como invitado',
    MINUTES_SHORT: 'min.',
    CHECKOUT_PAGE_TITLE: 'Cerrar sesión',
    CHECKOUT_NO_ITEMS: 'Su cesta de compras está vacía actualmente.',
    CHECKOUT_PRODUCT: 'Producto',
    CHECKOUT_QUANTITY: 'Cantidad',
    CHECKOUT_VAT: 'Impuesto sobre la venta.',
    CHECKOUT_PRICE: 'Precio',
    CHECKOUT_UNIT_PRICE: 'Precio por unidad',
    CHECKOUT_TYPE_GUIDED_TOUR: 'Visita guiada',
    CHECKOUT_TYPE_TICKET: 'Tiquete de entrada',
    CHECKOUT_TYPE_DAY_TICKET: 'Entrada',
    CHECKOUT_TOTAL: 'Total',
    CHECKOUT_DELETE_ARE_YOU_SURE: 'Está usted seguro?',
    CHECKOUT_DELETE_YES: 'Sí',
    CHECKOUT_DELETE_NO: 'No',
    CHECKOUT_BACK_TO_SHOPPING: 'Volver a la tienda',
    CHECKOUT_PAY_WITH: 'pagar con',
    CHECKOUT_CANCEL_ORDER: 'Cancelar la compra',
    CHECKOUT_SUCCESS_PAGE_TITLE: 'Pago realizado',
    CHECKOUT_SUCCESS_MESSAGE: 'Gracias por su compra!',
    CHECKOUT_SUCCESS_TICKETS_IN_EMAIL:
      '¡También recibirá sus boletos por correo electrónico (con PayPal Express a la dirección de correo electrónico registrada con PayPal)!',
    CHECKOUT_FAILED_MESSAGE:
      'Su pago no ha sido completado correctamente. Por favor, vuelva a intentarlo.',
    CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE: 'Pago aceptado.',
    CHECKOUT_CARD_ACCEPTED: 'Método de pago aceptado. Por favor, confirme su compra',
    CHECKOUT_CONFIRM_ORDER: 'Confirme su compra',
    CHECKOUT_NOT_LOGGEDIN_MESSAGE:
      'Usted tiene que ingresar a la cuenta antes de proceder a pagar.',
    CHECKOUT_PAYPAL_EXPRESS_NOT_LOGGEDIN_MESSAGE:
      'Para otros métodos de pago por favor inicie sesión',
    CHECKOUT_I_ACCEPT: 'Acepto',
    CHECKOUT_THE_TERMS: 'las condiciones',
    CHECKOUT_THE_PRIVACY_POLICY: 'Política de protección de datos',
    CHECKOUT_READ: 'Leer',
    CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE: 'Pago no aceptado.',
    CHECKOUT_PAYMENT_CANCELED_MESSAGE:
      'Su pago no ha sido aceptado. Usted quiere vaciar su cesta de compras?',
    CHECKOUT_CLEAR_CART: 'Eliminar contenido de cesta de compras.',
    CHECKOUT_KEEP_CART: 'Guardar contenido de cesta de compras.',
    CHECKOUT_CONFIRM_ORDER_PAGE_TITLE: 'Confirme su pedido',
    CHECKOUT_AS_GUEST_MESSAGE: 'O usted puede cerrar la sesión como huésped de una compra única.',
    CHECKOUT_PAYPAL_EXPRESS_AS_GUEST_MESSAGE: 'o echa un vistazo como invitado',
    CHECKOUT_AS_GUEST_BUTTON: 'Continuar como huésped.',
    CHECKOUT_SHIPPING_COSTS: 'Gastos de envío',
    CHECKOUT_SEND_INVOICE: 'Necesito factura',
    CHECKOUT_SEND_INVOICE_INFORMATION: 'Información sobre facturación',
    CHECKOUT_PAYPAL_EXPRESS_TITLE_SUFFIX: 'expreso',
    CHECKOUT_PAYPAL_EXPRESS_INFO: 'Utilice datos detallados de su Paypal',
    CHECKOUT_CART_GONE:
      'Su cesta de compras no ha sido encontrada. Tal vez su pedido ya fue pagado o usted sobrepasó el tiempo permitido para completar su pedido. Revise su dirección para la confirmación de la entrega de su pedido o intente volver a comprar los artículos.',
    CHECKOUT_NOT_PAYABLE: 'Su cesta de compras no puede ser completada!',
    CHECKOUT_NO_PAY: 'Continúe',
    SHOPPING_CART: 'Cesta de compras',
    DIRECT_DEBIT: 'Débito directo',
    CHECKOUT_CANCEL: 'Cancelar',
    CHECKOUT_NO_SHIPPING_COSTS_FOR_COUNTRY:
      'No hay datos sobre gastos de envío para este país. Favor de contactarnos.',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_TEXT: 'Aquí encontrará detalles sobre su',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_LINK: 'derecho de desistimiento',

    NAV_VOUCHERS: 'Cupones',
    VOUCHER_PAGE_TITLE: 'Cupones',
    VOUCHER_CATEGORY_PAGE_TITLE: 'Tienda',
    VOUCHER_TOTAL: 'Total',
    VOUCHER_UNIT_PRICE: 'Por cada artículo',
    VOUCHER_ADDED_TO_CART: 'Cupón añadido a la cesta de compras.',
    VOUCHER_QUANTITY: 'Cantidad',
    VOUCHER_BUY: 'Compre',
    VOUCHER_OUT_OF_STOCK: 'Artículo está agotado actualmente.',
    VOUCHER_EXPIRED: 'El artículo ya no está disponible',
    VOUCHER_BUY_SUFFIX_SINGULAR: 'cupón ',
    VOUCHER_BUY_SUFFIX_PLURAL: 'cupones',
    VOUCHER: 'cupón',
    VOUCHER_CODE: 'Código del cupón',
    VOUCHER_GET_BUTTON: 'Canjear cupón',
    VOUCHER_REMAINING_AMOUNT: 'Suma restante',
    VOUCHER_SELECTABLE_PRICE: 'Precio puede ser seleccionado libremente',
    VOUCHER_VALUE: 'Ingrese el  precio',
    SHIPPING_TITLE: 'Dirección de envío',
    SHIPPING_CHANGE: 'Cambiar',
    SHIPPING_SAME_AS_BILLING: 'Dirección de envío corresponde a dirección de la factura.',
    SHIPPING_FILL_ALL_FIELDS:
      'Por favor, rellene todos los espacios necesarios para dirección de envío.',
    SHIPPING_WEIGTH_FROM: 'Peso desde',
    BILLING_TITLE: 'Información sobre facturación',
    BILLING_CHANGE: 'Cambiar',
    BILLING_SHIPPING_SAVE: 'Guardar',
    MERCHANDISE_PAGE_TITLE: 'Tienda',
    MERCHANDISE_CATEGORY_PAGE_TITLE: 'Tienda',
    MERCHANDISE_TOTAL: 'Total ',
    MERCHANDISE_UNIT_PRICE: 'Precio por unidad',
    MERCHANDISE_ADDED_TO_CART: 'Producto añadido a la cesta de compras.',
    MERCHANDISE_QUANTITY: 'Cantidad',
    MERCHANDISE_BUY: 'Compre',
    MERCHANDISE_BUY_SUFFIX_SINGULAR: 'Artículo',
    MERCHANDISE_BUY_SUFFIX_PLURAL: 'Artículos',
    MERCHANDISE_OUT_OF_STOCK: 'Artículo está agotado actualmente',
    MERCHANDISE_SOLDOUT: 'agotado',

    ANNUAL_TICKETS_CARD: 'Tarjeta',
    ANNUAL_TICKETS_NAME: 'Título, Nombre, Apellido',
    ANNUAL_TICKETS_SENT_TO_CUSTOM_ADDRESS: 'Enviar a dirección personalizada',
    ANNUAL_TICKETS_ADDRESS: '',

    USER_SETTINGS_SAVED: 'Configuracion guardada',
    USER_LOGIN_PAGE_TITLE: 'Iniciar sesión',
    USER_REGISTER_PAGE_TITLE: 'Registro',
    USER_FORGOT_PAGE_TITLE: 'Olvidé las credenciales',
    USER_RECOVER_PAGE_TITLE: 'Recupere su cuenta',
    USER_FORGOT_SUCCESS_PAGE_TITLE: 'Restablecimiento de contraseña exitoso',
    PRODUCT_PAGE_TITLE: 'Artículo',
    PAGE_NOT_FOUND_TITLE: 'Página no encontrada',
    PAGE_NOT_FOUND_MESSAGE: 'La página que usted estaba buscando no ha sido encontrada',
    USER_REGISTER_SUCCESS_PAGE_TITLE: 'Usuario registrado correctamente',
    REGISTRATION_SUCCESS_TITLE: 'Registro aceptado',
    REGISTRATION_SUCCESS_TEXT: 'Su cuenta ya se ha creado',
    FORGOT_PASSWORD_PAGE_TITLE: 'Contraseña olvidada',
    RECOVER_PAGE_TITLE: 'Recupere su cuenta',
    FOOTER_IMPRINT_LINK: 'Imprimir',
    FOOTER_TERMS_LINK_GTC: 'GTC',
    FOOTER_TERMS_LINK_DATA_PROTECTION: 'Normativas de protección de datos',
    FOOTER_SHIPPING: 'Gastos de envío',
    INTERNAL_SERVER_ERROR_MESSAGE_PREFIX:
      'Se ha producido un error inesperado. Esto nunca debería suceder. Por favor contáctenos a',

    INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX: 'si requiere más ayuda',
    PAYMENT_METHOD_CHOICE: 'Seleccione por favor',
    REQUIRED_FIELDS: 'Espacios obligatorios',
    SALUTATION_PLACEHOLDER: 'Seleccione por favor.',
    PROGRESS_BAR_CART: 'Cesta de compras',
    PROGRESS_BAR_LOGIN: 'Inicie sesión',
    PROGRESS_BAR_PAYMENT: 'Pago aceptado',
    PROGRESS_BAR_COMPLETE: 'Complete ',
    RESET_CATEGORY: 'Reiniciar',
    SELECT_INPUT_PLACEHOLDER: 'Seleccione por favor',
    COOKIE_ACCEPT: 'Acepto',

    EXTERNAL_DOCUMENT_NUMBER: 'Número de documento externo',
    EXTERNAL_NUMBER_CONFIRM: 'Confirmar',
    EXTERNAL_NUMBER_CHANGE: 'Cambiar',
  },
  da: {
    MUSEUM: ' ',
    LANG_FULL: 'Dansk',
    DATE_FORMAT: 'd.m.Y',
    HIGHLIGHT_TITLE: 'Hjerteligt velkommen',
    HIGHLIGHT_DESCRIPTION: ' ',
    NAV_MERCHANDISE: 'Butik',
    NAV_TICKETS: 'Billetter',
    NAV_GUIDED_TOURS: 'Rundvisninger',
    NAV_ALL: 'Alt',
    NAV_BOOKS: 'Bøger',
    NAV_POSTERS: 'Plakater',
    NAV_LOGIN: 'Log ind',
    NAV_REGISTER: 'Ny registrering',
    NAV_SETTINGS: 'Indstillinger',
    NAV_LOGOUT: 'Log ud',
    NAV_BACK: 'Tilbage',
    NAV_BACK_TO: 'Tilbage til',
    NAV_REGISTER_AS_GUEST: 'Faktureringsadresse',
    NAV_DAY_TICKETS: 'Billetter',
    NAV_MEMCARD: 'Årsbilletter',
    BREADCRUMBS_MUSEUM_HOME: 'Hjem',
    BREADCRUMBS_WEBSHOP: 'Webshop',
    GO_HOME: 'Fortsæt med at handle',
    ALL_PRICES_INCLUDE_VAT: 'alle priser inkl. lovpligtig moms',
    ALL_PRICES_INCLUDE_VAT_SHORT: 'inkl. moms',
    DATE_PICKER_UNAVAILABLE_DAY: 'Dag er ikke tilgængelig',
    DATE_PICKER_SOLDOUT_DAY: 'Dag er udsolgt',
    TIME_PICKER_SOLDOUT_TIME: 'Tiden er udsolgt',
    TICKETS_PAGE_TITLE: 'Billetter',
    TICKETS_TIME: 'Åbningstider',
    TICKETS_BUY_PREFIX: '',
    TICKETS_BUY_SUFFIX: 'køb',
    TICKETS_TICKETS: 'Billetter',
    TICKETS_TICKET: 'Billet',
    TICKETS_FROM: 'fra',
    TICKETS_TO: 'til',
    TICKETS_SELECT_A_DATE: 'Vælg venligst den ønskede dato',
    TICKETS_ADDED_TO_CART: 'Billetter tilføjet til indkøbskurv',
    TICKETS_TOTAL: 'Samlet',
    TICKETS_WRONG_FAMILY_TICKET_COUNT: 'Vælg venligst en mængde for hver delbillet',
    TICKETS_MOBILE_TICKETS: 'Vis mobilbillet',
    TICKETS_EXPIRED: 'Denne begivenhed er forbi!',
    TICKETS_OVERBOOKING_MSG_PART1: 'Du forsøger',
    TICKETS_OVERBOOKING_MSG_PART2: 'at booke billetter, men der er kun',
    TICKETS_OVERBOOKING_MSG_PART3: 'tilgængelig',
    TICKETS_TIME_UNAVAILABLE: 'Billetter udsolgt',
    TICKETS_NO_EVENTS: 'Ingen resultater for dette valg',
    TICKETS_NO_EVENTS_MATCHING_FILTERS: 'Ingen resultater for dette valg',
    DAY_TICKETS_PAGE_TITLE: 'Billetter',
    GUIDED_TOURS_PAGE_TITLE: 'Rundvisninger',
    GUIDED_TOURS_NO_TOURS: 'Ingen ture for de valgte filtre',
    GUIDED_TOURS_PRICES_FROM: 'Pris fra:',
    GUIDED_TOURS_ANY_DATE: 'Dato',
    GUIDED_TOURS_TODAY: 'I dag',
    GUIDED_TOURS_TOMORROW: 'I morgen',
    GUIDED_TOUR_SINGLE_PAGE_TITLE: 'Rundvisninger',
    GUIDED_TOUR_SELECT_DATE: 'Vælg venligst en dato',
    GUIDED_TOUR_SELECT_TIME: 'Vælg venligst en tid',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART1:
      'Denne rundvisning kan kun bookes for rundvisninger med mindst',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_SINGULAR: 'personer',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_PLURAL: 'Personer',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART1: 'Du forsøger',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART2: 'at booke billetter, men der er kun',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART3: 'tilgængelig',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART1: 'Du forsøger at booke rundvisningspladser til',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART2: 'personer, men der er kun',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART3: 'tilgængelig',
    GUIDED_TOUR_MORE_TICKETS_THAN_TOURS_MSG:
      'Antallet af billetter og rundvisningspladser skal være det samme',
    GUIDED_TOUR_LESS_TICKETS_THAN_TOURS_MSG:
      'Antallet af billetter og rundvisningspladser skal være det samme',
    GUIDED_TOUR_PARTICIPANTS: 'Personer',
    GUIDED_TOUR_TOTAL_PRICE: 'Samlet',
    GUIDED_TOUR_AVAILABLE_TICKETS_FOR_SELECTED_TIME: 'Tilgængelige billetter til den valgte tid',
    GUIDED_TOUR_AVAILABLE_PARTICIPANTS_FOR_SELECTED_TIME:
      'Tilgængelige rundvisningspladser til den valgte tid',
    GUIDED_TOUR_BUY_TICKETS_PREFIX: 'Rundvisning med',
    GUIDED_TOUR_BUY_TICKETS_SUFFIX: 'Køb billetter',
    GUIDED_TOUR_BUY: 'Køb',
    GUIDED_TOUR_ADDED_TO_CART: 'Rundvisning tilføjet til indkøbskurv',
    GUIDED_TOUR_VAT: 'Moms',
    GUIDED_TOUR_QUESTIONS: 'Yderligere oplysninger om gruppen',
    GUIDED_TOUR_FILTER_LANGUAGE: 'Sprog',
    GUIDED_TOUR_NOT_AVAILABLE: 'Rundvisning ikke tilgængelig',
    GUIDED_TOUR_SOLD_OUT: 'Denne rundvisning er udsolgt',
    GUIDED_TOUR_NO_TICKET_AVAILABLE: '',
    GUIDED_TOUR_NOT_AVAILABLE_THIS_DAY_BUT_AVAILABLE_ON:
      'Rundvisningen er ikke tilgængelig for den valgte dato. Den næste tilgængelige dato er',
    GUIDED_TOUR_SHOW_ME: 'vis',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_1: 'fra',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_2: 'ture valgt. Tilbyder plads til maksimalt',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_3: 'deltagere.',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B1: 'Rundvisninger med maks.',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B2: 'deltagere pr. rundvisning',
    GUIDED_TOUR_IS_NOT_BOOKABLE: 'ikke tilgængelig i øjeblikket',
    FIELD_IS_REQUIRED: 'Udfyld venligst alle obligatoriske felter.',
    AUTH_SALUTATION_LABEL: 'Titel',
    AUTH_EMAIL_LABEL: 'E-mail',
    AUTH_EMAIL_CONFIRM_LABEL: 'E-mail bekræftelse',
    AUTH_EMAILS_DO_NOT_MATCH: 'E-mail-adresserne stemmer ikke overens',
    AUTH_PASSWORD_LABEL: 'Adgangskode',
    AUTH_PASSWORD_CONFIRM_LABEL: 'Adgangskode bekræftelse',
    AUTH_PASSWORDS_DO_NOT_MATCH: 'Adgangskoderne stemmer ikke overens',
    AUTH_COMPANY_LABEL: 'Firma',
    AUTH_PHONE_LABEL: 'Telefon',
    AUTH_FIRST_NAME_LABEL: 'Fornavn',
    AUTH_LAST_NAME_LABEL: 'Efternavn',
    AUTH_CITY_LABEL: 'By',
    AUTH_ADDRESS_LABEL: 'Adresse (gade, husnummer)',
    AUTH_COUNTRY_LABEL: 'Land',
    AUTH_ZIP_LABEL: 'Postnummer',
    AUTH_FORM_HAS_ERRORS: 'Korriger venligst de relevante felter',
    AUTH_NAME: 'Navn',
    AUTH_BTN_LOGIN: 'Log ind',
    AUTH_BTN_REGISTER: 'Ny registrering',
    AUTH_BTN_RECOVER: 'Gendan',
    AUTH_BTN_REGISTER_GUEST: 'book som gæst',
    AUTH_BTN_SAVE: 'Gem',
    AUTH_FORGOT_PASSWORD: 'Glemt adgangskode?',
    AUTH_DONT_HAVE_ACCOUNT_QUESTION: 'Har du endnu ikke en konto?',
    AUTH_ALREADY_HAVE_ACCOUNT_QUESTION: 'Har du allerede en konto?',
    AUTH_TRIED_TO_ACCESS_UNAUTHORIZED_PAGE: 'Denne side kræver login',
    AUTH_SENT_WRONG_CREDENTIALS: 'Brugernavn/e-mail eller adgangskode forkert!',
    AUTH_FORGOT_INVALID_EMAIL: 'E-mail ikke gyldig',
    AUTH_LOGGING_IN: 'Log ind kører',
    AUTH_LOGIN_SUCCESS: 'Log ind succesfuld',
    AUTH_LOGIN_FAILED: 'Log ind mislykkedes',
    AUTH_REQUESTING_RESET: 'Nulstil adgangskode',
    AUTH_REQUEST_RESET_SUCCESS: 'Ændring af adgangskode succesfuld',
    AUTH_REQUEST_RESET_FAIL: 'Ændring af adgangskode mislykkedes',
    AUTH_SUCCESS_RESET_TITLE: 'Ændring af adgangskode succesfuld',
    AUTH_SUCCESS_RESET_MESSAGE: 'Du vil modtage en e-mail med instruktioner',
    AUTH_NEW_PASSWORD_LABEL: 'Ny adgangskode',
    AUTH_RECOVER_ACCOUNT: 'Anmod om adgangskode',
    AUTH_SETTINGS_PAGE_TITLE: 'Indstillinger',
    AUTH_LOGIN_PAGE_TITLE: 'Log ind',
    AUTH_SETTING_NEW_PASSWORD: 'Ny adgangskode gemmes...',
    AUTH_SETTING_NEW_PASSWORD_FAILED: 'Ikke succesfuld',
    AUTH_SETTING_NEW_PASSWORD_SUCCESS: 'Adgangskode er gemt',
    AUTH_RECOVER_SUCCESS_PAGE_TITLE: 'Konto gendannet',
    AUTH_RECOVER_SUCCESS_TITLE: 'Kontoen er blevet gendannet',
    AUTH_RECOVER_SUCCESS_MESSAGE: 'Du er automatisk logget ind.',
    AUTH_CURRENT_PASSWORD_LABEL: 'Aktuel adgangskode',
    AUTH_PASSWORD_CHANGE_REQUEST: 'Adgangskode gemmes...',
    AUTH_PASSWORD_CHANGE_SUCCESS: 'Adgangskode ændret',
    AUTH_PASSWORD_CHANGE_FAILED: 'Ikke succesfuld',
    AUTH_EMAIL_CHANGE_SUCCESS: '',
    AUTH_ACCOUNT_SETTINGS_TITLE: 'Skift e-mail',
    AUTH_ACCOUNT_CHANGE_PASSWORD: 'Skift adgangskode',
    AUTH_ACCOUNT_CHANGE_EMAIL: 'Skift e-mail',
    AUTH_SHIPPING_DETAILS: 'Leveringsadresse',
    AUTH_USER_DETAILS: 'Brugeroplysninger',
    AUTH_USER_SETTINGS_PAGE_TITLE: 'Brugerindstillinger',
    GUEST_REGISTER_PAGE_TITLE: 'Faktureringsadresse',
    MINUTES_SHORT: 'min.',
    CHECKOUT_PAGE_TITLE: 'Checkout',
    CHECKOUT_NO_ITEMS: 'Din indkøbskurv er i øjeblikket tom',
    CHECKOUT_PRODUCT: 'Vare',
    CHECKOUT_QUANTITY: 'Antal',
    CHECKOUT_VAT: 'Moms',
    CHECKOUT_PRICE: 'Pris',
    CHECKOUT_UNIT_PRICE: 'Stykpris',
    CHECKOUT_TYPE_GUIDED_TOUR: 'Rundvisning',
    CHECKOUT_TYPE_TICKET: 'Indgangsbillet',
    CHECKOUT_TYPE_DAY_TICKET: 'Billet',
    CHECKOUT_TOTAL: 'Samlet',
    CHECKOUT_DELETE_ARE_YOU_SURE: 'Er du sikker?',
    CHECKOUT_DELETE_YES: 'Ja',
    CHECKOUT_DELETE_NO: 'Nej',
    CHECKOUT_BACK_TO_SHOPPING: 'Fortsæt med at handle',
    CHECKOUT_PAY_WITH: 'Betal med',
    CHECKOUT_CANCEL_ORDER: 'Afbryd',
    CHECKOUT_SUCCESS_PAGE_TITLE: 'Betaling succesfuld',
    CHECKOUT_SUCCESS_MESSAGE: 'Tak for din bestilling!',
    CHECKOUT_SUCCESS_TICKETS_IN_EMAIL:
      'Du modtager også dine billetter via e-mail (ved PayPal Express til den e-mailadresse, der er registreret hos PayPal)!',
    CHECKOUT_FAILED_MESSAGE: 'Betaling ikke succesfuld',
    CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE: 'Betalingsmetode accepteret',
    CHECKOUT_CARD_ACCEPTED: 'Betalingsdata bekræftet – gennemfør venligst bestillingen!',
    CHECKOUT_CONFIRM_ORDER: 'Bestil med betalingsforpligtelse',
    CHECKOUT_NOT_LOGGEDIN_MESSAGE: 'Log ind, før du fortsætter med betalingen',
    CHECKOUT_PAYPAL_EXPRESS_NOT_LOGGEDIN_MESSAGE: 'For andre betalingsmetoder log venligst ind',
    CHECKOUT_I_ACCEPT: 'Jeg accepterer',
    CHECKOUT_THE_TERMS: 'Vilkår og betingelser',
    CHECKOUT_THE_PRIVACY_POLICY: 'Privatlivspolitik',
    CHECKOUT_READ: 'Læs',
    CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE: 'Bestilling afbrudt',
    CHECKOUT_PAYMENT_CANCELED_MESSAGE: 'Din betaling blev afbrudt. Vil du tømme indkøbskurven?',
    CHECKOUT_CLEAR_CART: 'Tøm indkøbskurv',
    CHECKOUT_KEEP_CART: 'Tilbage til indkøbskurven',
    CHECKOUT_CONFIRM_ORDER_PAGE_TITLE: 'Gennemse og send bestilling',
    CHECKOUT_AS_GUEST_MESSAGE: 'Du kan også booke som gæst',
    CHECKOUT_PAYPAL_EXPRESS_AS_GUEST_MESSAGE: 'eller checkout som gæst',
    CHECKOUT_AS_GUEST_BUTTON: 'Book som gæst',
    CHECKOUT_SHIPPING_COSTS: 'Forsendelsesomkostninger',
    CHECKOUT_SEND_INVOICE: 'Faktura ønskes',
    CHECKOUT_SEND_INVOICE_INFORMATION: 'Faktureringsadresse',
    CHECKOUT_PAYPAL_EXPRESS_TITLE_SUFFIX: 'Express (overtag data)',
    CHECKOUT_PAYPAL_EXPRESS_INFO: 'Brug dine PayPal-oplysninger',
    CHECKOUT_CART_GONE:
      'Din indkøbskurv er ikke længere tilgængelig. Enten er din bestilling allerede afsluttet, eller processen tog for lang tid. Kontroller venligst dine e-mails for en bookingbekræftelse eller prøv igen senere.',
    CHECKOUT_NOT_PAYABLE: 'Indkøbskurven kan ikke afsluttes!',
    CHECKOUT_NO_PAY: 'Fortsæt',
    SHOPPING_CART: 'Indkøbskurv',
    DIRECT_DEBIT: 'Direkte debitering',
    CHECKOUT_CANCEL: 'Annuller',
    CHECKOUT_NO_SHIPPING_COSTS_FOR_COUNTRY:
      'Ingen porto omkostninger for dette land. Kontakt venligst os.',
    CHECKOUT_COMPLETE_ORDER: 'Gennemfør bestilling',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_TEXT: 'Her finder du detaljer om din',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_LINK: 'fortrydelsesret',
    NAV_VOUCHERS: 'Gavekort',
    VOUCHER_PAGE_TITLE: 'Gavekort',
    VOUCHER_CATEGORY_PAGE_TITLE: 'Gavekort',
    VOUCHER_TOTAL: 'Samlet',
    VOUCHER_ADDED_TO_CART: 'Vare er tilføjet til indkøbskurv',
    VOUCHER_UNIT_PRICE: 'pr. styk',
    VOUCHER_QUANTITY: 'Antal',
    VOUCHER_BUY: 'Køb',
    VOUCHER_OUT_OF_STOCK: 'Vare ikke tilgængelig',
    VOUCHER_EXPIRED: 'Varen er ikke længere tilgængelig',
    VOUCHER_BUY_SUFFIX_SINGULAR: 'Køb gavekort',
    VOUCHER_BUY_SUFFIX_PLURAL: 'Køb gavekort',
    VOUCHER: 'Gavekort',
    VOUCHER_CODE: 'Gavekortkode',
    VOUCHER_GET_BUTTON: 'Indløs',
    VOUCHER_REMAINING_AMOUNT: 'Restværdi',
    VOUCHER_SELECTABLE_PRICE: 'Pris frit valg',
    VOUCHER_VALUE: 'Indtast værdi',
    SHIPPING_TITLE: 'Leveringsadresse',
    SHIPPING_CHANGE: 'Ændre',
    SHIPPING_SAME_AS_BILLING: 'Samme leveringsadresse',
    SHIPPING_FILL_ALL_FIELDS: 'Udfyld venligst alle felter for leveringsadressen',
    SHIPPING_WEIGTH_FROM: 'Vægt fra',
    BILLING_TITLE: 'Faktureringsadresse',
    BILLING_CHANGE: 'Ændre',
    BILLING_SHIPPING_SAVE: 'Gem',
    MERCHANDISE_PAGE_TITLE: 'Butik',
    MERCHANDISE_CATEGORY_PAGE_TITLE: 'Butik',
    MERCHANDISE_TOTAL: 'Samlet',
    MERCHANDISE_UNIT_PRICE: 'pr. styk',
    MERCHANDISE_ADDED_TO_CART: 'Vare er tilføjet til indkøbskurv',
    MERCHANDISE_QUANTITY: 'Antal',
    MERCHANDISE_BUY: 'Køb',
    MERCHANDISE_BUY_SUFFIX_SINGULAR: 'Køb vare',
    MERCHANDISE_BUY_SUFFIX_PLURAL: 'Køb vare',
    MERCHANDISE_OUT_OF_STOCK: 'Vare ikke tilgængelig',
    MERCHANDISE_SOLDOUT: 'udsolgt',
    ANNUAL_TICKETS_CARD: 'Kort',
    ANNUAL_TICKETS_NAME: 'Titel, Fornavn, Efternavn',
    ANNUAL_TICKETS_SENT_TO_CUSTOM_ADDRESS: 'Send til tilpasset adresse',
    ANNUAL_TICKETS_ADDRESS: 'adresse',
    USER_SETTINGS_SAVED: 'Indstillinger gemt',
    USER_LOGIN_PAGE_TITLE: 'Log ind',
    USER_REGISTER_PAGE_TITLE: 'Ny registrering',
    USER_FORGOT_PAGE_TITLE: 'Glemt loginoplysninger',
    USER_RECOVER_PAGE_TITLE: 'Gendan konto',
    USER_FORGOT_SUCCESS_PAGE_TITLE: 'Adgangskodereset var succesfuld',
    PRODUCT_PAGE_TITLE: 'Vare',
    PAGE_NOT_FOUND_TITLE: 'Side ikke fundet',
    PAGE_NOT_FOUND_MESSAGE: 'Siden kunne ikke findes',
    USER_REGISTER_SUCCESS_PAGE_TITLE: 'Log ind succesfuld',
    REGISTRATION_SUCCESS_TITLE: 'Log ind accepteret',
    REGISTRATION_SUCCESS_TEXT: 'Din konto er oprettet',
    FORGOT_PASSWORD_PAGE_TITLE: 'Glemt adgangskode',
    RECOVER_PAGE_TITLE: 'Gendan konto',
    FOOTER_IMPRINT_LINK: 'Aftryk',
    FOOTER_TERMS_LINK_GTC: 'Vilkår og betingelser',
    FOOTER_TERMS_LINK_DATA_PROTECTION: 'Privatlivspolitik',
    FOOTER_SHIPPING: 'Forsendelsesomkostninger',
    INTERNAL_SERVER_ERROR_MESSAGE_PREFIX:
      'Der opstod en uventet fejl. For yderligere support kontakt os venligst via',
    INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX: '.',
    PAYMENT_METHOD_CHOICE: 'Vælg venligst',
    REQUIRED_FIELDS: 'Obligatoriske oplysninger',
    SALUTATION_PLACEHOLDER: 'Vælg venligst',
    PROGRESS_BAR_CART: 'indkøbskurv',
    PROGRESS_BAR_LOGIN: 'log ind',
    PROGRESS_BAR_PAYMENT: 'betaling',
    PROGRESS_BAR_COMPLETE: 'fuldfør',
    RESET_CATEGORY: 'nulstil',
    SELECT_INPUT_PLACEHOLDER: 'vælg venligst...',
    COOKIE_ACCEPT: 'Jeg accepterer!',
    EXTERNAL_DOCUMENT_NUMBER: 'Ekstern dokumentnummer',
    EXTERNAL_NUMBER_CONFIRM: 'Bekræft',
    EXTERNAL_NUMBER_CHANGE: 'Ændre',
  },
  sv: {
    MUSEUM: ' ',
    LANG_FULL: 'Svenska',
    DATE_FORMAT: 'd.m.Y',
    HIGHLIGHT_TITLE: 'Välkommen',
    HIGHLIGHT_DESCRIPTION: ' ',
    NAV_MERCHANDISE: 'Butik',
    NAV_TICKETS: 'Biljetter',
    NAV_GUIDED_TOURS: 'Guidningar',
    NAV_ALL: 'Allt',
    NAV_BOOKS: 'Böcker',
    NAV_POSTERS: 'Affischer',
    NAV_LOGIN: 'Logga in',
    NAV_REGISTER: 'Registrera dig',
    NAV_SETTINGS: 'Inställningar',
    NAV_LOGOUT: 'Logga ut',
    NAV_BACK: 'Tillbaka',
    NAV_BACK_TO: 'Tillbaka till',
    NAV_REGISTER_AS_GUEST: 'Faktureringsadress',
    NAV_DAY_TICKETS: 'Biljetter',
    NAV_MEMCARD: 'Årsbiljetter',
    BREADCRUMBS_MUSEUM_HOME: 'Hem',
    BREADCRUMBS_WEBSHOP: 'Webbshop',
    GO_HOME: 'Fortsätt handla',
    ALL_PRICES_INCLUDE_VAT: 'alla priser inkl. lagstadgad moms',
    ALL_PRICES_INCLUDE_VAT_SHORT: 'inkl. moms',
    DATE_PICKER_UNAVAILABLE_DAY: 'Dagen är inte tillgänglig',
    DATE_PICKER_SOLDOUT_DAY: 'Dagen är slutsåld',
    TIME_PICKER_SOLDOUT_TIME: 'Tiden är slutsåld',
    TICKETS_PAGE_TITLE: 'Biljetter',
    TICKETS_TIME: 'Öppettider',
    TICKETS_BUY_PREFIX: '',
    TICKETS_BUY_SUFFIX: 'köpa',
    TICKETS_TICKETS: 'Biljetter',
    TICKETS_TICKET: 'Biljett',
    TICKETS_FROM: 'från',
    TICKETS_TO: 'till',
    TICKETS_SELECT_A_DATE: 'Vänligen välj önskat datum',
    TICKETS_ADDED_TO_CART: 'Biljetter har lagts till i varukorgen',
    TICKETS_TOTAL: 'Totalt',
    TICKETS_WRONG_FAMILY_TICKET_COUNT: 'Vänligen välj antal för varje delbiljett',
    TICKETS_MOBILE_TICKETS: 'Visa mobilbiljett',
    TICKETS_EXPIRED: 'Denna evenemang har avslutats!',
    TICKETS_OVERBOOKING_MSG_PART1: 'Du försöker',
    TICKETS_OVERBOOKING_MSG_PART2: 'boka biljetter men det finns bara',
    TICKETS_OVERBOOKING_MSG_PART3: 'tillgängliga',
    TICKETS_TIME_UNAVAILABLE: 'Biljetter slutsålda',
    TICKETS_NO_EVENTS: 'Inga resultat för detta val',
    TICKETS_NO_EVENTS_MATCHING_FILTERS: 'Inga resultat för detta val',
    DAY_TICKETS_PAGE_TITLE: 'Biljetter',
    GUIDED_TOURS_PAGE_TITLE: 'Guidningar',
    GUIDED_TOURS_NO_TOURS: 'Inga turer för de valda filtren',
    GUIDED_TOURS_PRICES_FROM: 'Pris från:',
    GUIDED_TOURS_ANY_DATE: 'Datum',
    GUIDED_TOURS_TODAY: 'Idag',
    GUIDED_TOURS_TOMORROW: 'Imorgon',
    GUIDED_TOUR_SINGLE_PAGE_TITLE: 'Guidningar',
    GUIDED_TOUR_SELECT_DATE: 'Vänligen välj ett datum',
    GUIDED_TOUR_SELECT_TIME: 'Vänligen välj en tid',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART1: 'Denna guidning kan endast bokas för guidningar med minst',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_SINGULAR: 'personer',
    GUIDED_TOUR_UNDERBOOKING_MSG_PART2_PLURAL: 'Personer',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART1: 'Du försöker',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART2: 'boka biljetter men det finns bara',
    GUIDED_TOUR_OVERBOOKING_TICKETS_MSG_PART3: 'tillgängliga',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART1: 'Du försöker boka guidningsplatser för',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART2: 'personer men det finns bara',
    GUIDED_TOUR_OVERBOOKING_PARTICIPANTS_MSG_PART3: 'tillgängliga',
    GUIDED_TOUR_MORE_TICKETS_THAN_TOURS_MSG:
      'Antalet biljetter och guidningsplatser ska vara detsamma',
    GUIDED_TOUR_LESS_TICKETS_THAN_TOURS_MSG:
      'Antalet biljetter och guidningsplatser ska vara detsamma',
    GUIDED_TOUR_PARTICIPANTS: 'Personer',
    GUIDED_TOUR_TOTAL_PRICE: 'Totalt',
    GUIDED_TOUR_AVAILABLE_TICKETS_FOR_SELECTED_TIME: 'Tillgängliga biljetter för vald tid',
    GUIDED_TOUR_AVAILABLE_PARTICIPANTS_FOR_SELECTED_TIME:
      'Tillgängliga guidningsplatser för vald tid',
    GUIDED_TOUR_BUY_TICKETS_PREFIX: 'Guidning med',
    GUIDED_TOUR_BUY_TICKETS_SUFFIX: 'Köp biljetter',
    GUIDED_TOUR_BUY: 'Köp',
    GUIDED_TOUR_ADDED_TO_CART: 'Guidning har lagts till i varukorgen',
    GUIDED_TOUR_VAT: 'Moms',
    GUIDED_TOUR_QUESTIONS: 'Ytterligare information om gruppen',
    GUIDED_TOUR_FILTER_LANGUAGE: 'Språk',
    GUIDED_TOUR_NOT_AVAILABLE: 'Guidning inte tillgänglig',
    GUIDED_TOUR_SOLD_OUT: 'Denna guidning är slutsåld',
    GUIDED_TOUR_NO_TICKET_AVAILABLE: '',
    GUIDED_TOUR_NOT_AVAILABLE_THIS_DAY_BUT_AVAILABLE_ON:
      'Guidningen är inte tillgänglig för valt datum. Nästa tillgängliga datum är',
    GUIDED_TOUR_SHOW_ME: 'visa',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_1: 'från',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_2: 'Turer valda. Rymmer upp till',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_3: 'deltagare.',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B1: 'Guidningar med max.',
    GUIDED_TOUR_SELECTED_TOUR_MESSAGE_B2: 'deltagare per guidning',
    GUIDED_TOUR_IS_NOT_BOOKABLE: 'för närvarande inte bokningsbar',
    FIELD_IS_REQUIRED: 'Vänligen svara på alla obligatoriska fält.',
    AUTH_SALUTATION_LABEL: 'Titel',
    AUTH_EMAIL_LABEL: 'E-post',
    AUTH_EMAIL_CONFIRM_LABEL: 'E-postbekräftelse',
    AUTH_EMAILS_DO_NOT_MATCH: 'E-postadresserna matchar inte',
    AUTH_PASSWORD_LABEL: 'Lösenord',
    AUTH_PASSWORD_CONFIRM_LABEL: 'Lösenordsbekräftelse',
    AUTH_PASSWORDS_DO_NOT_MATCH: 'Lösenorden matchar inte',
    AUTH_COMPANY_LABEL: 'Företag',
    AUTH_PHONE_LABEL: 'Telefon',
    AUTH_FIRST_NAME_LABEL: 'Förnamn',
    AUTH_LAST_NAME_LABEL: 'Efternamn',
    AUTH_CITY_LABEL: 'Ort',
    AUTH_ADDRESS_LABEL: 'Adress (gata, husnummer)',
    AUTH_COUNTRY_LABEL: 'Land',
    AUTH_ZIP_LABEL: 'Postnummer',
    AUTH_FORM_HAS_ERRORS: 'Korrigera de relevanta fälten',
    AUTH_NAME: 'Namn',
    AUTH_BTN_LOGIN: 'Logga in',
    AUTH_BTN_REGISTER: 'Registrera dig',
    AUTH_BTN_RECOVER: 'Återställ',
    AUTH_BTN_REGISTER_GUEST: 'boka som gäst',
    AUTH_BTN_SAVE: 'Spara',
    AUTH_FORGOT_PASSWORD: 'Glömt lösenord?',
    AUTH_DONT_HAVE_ACCOUNT_QUESTION: 'Har du inget konto än?',
    AUTH_ALREADY_HAVE_ACCOUNT_QUESTION: 'Har du redan ett konto?',
    AUTH_TRIED_TO_ACCESS_UNAUTHORIZED_PAGE: 'Denna sida kräver inloggning',
    AUTH_SENT_WRONG_CREDENTIALS: 'Användarnamn/e-post eller lösenord fel!',
    AUTH_FORGOT_INVALID_EMAIL: 'E-post ogiltig',
    AUTH_LOGGING_IN: 'Inloggning pågår',
    AUTH_LOGIN_SUCCESS: 'Inloggning lyckades',
    AUTH_LOGIN_FAILED: 'Inloggning misslyckades',
    AUTH_REQUESTING_RESET: 'Återställ lösenord',
    AUTH_REQUEST_RESET_SUCCESS: 'Lösenordsändring lyckades',
    AUTH_REQUEST_RESET_FAIL: 'Lösenordsändring misslyckades',
    AUTH_SUCCESS_RESET_TITLE: 'Lösenordsändring lyckades',
    AUTH_SUCCESS_RESET_MESSAGE: 'Du kommer att få ett e-postmeddelande med instruktioner',
    AUTH_NEW_PASSWORD_LABEL: 'Nytt lösenord',
    AUTH_RECOVER_ACCOUNT: 'Begär lösenord',
    AUTH_SETTINGS_PAGE_TITLE: 'Inställningar',
    AUTH_LOGIN_PAGE_TITLE: 'Logga in',
    AUTH_SETTING_NEW_PASSWORD: 'Nytt lösenord sparas...',
    AUTH_SETTING_NEW_PASSWORD_FAILED: 'Ej lyckades',
    AUTH_SETTING_NEW_PASSWORD_SUCCESS: 'Lösenordet är sparat',
    AUTH_RECOVER_SUCCESS_PAGE_TITLE: 'Konto återställt',
    AUTH_RECOVER_SUCCESS_TITLE: 'Konto har återställts',
    AUTH_RECOVER_SUCCESS_MESSAGE: 'Du är automatiskt inloggad.',
    AUTH_CURRENT_PASSWORD_LABEL: 'Aktuellt lösenord',
    AUTH_PASSWORD_CHANGE_REQUEST: 'Lösenord sparas...',
    AUTH_PASSWORD_CHANGE_SUCCESS: 'Lösenord ändrat',
    AUTH_PASSWORD_CHANGE_FAILED: 'Ej lyckades',
    AUTH_EMAIL_CHANGE_SUCCESS: '',
    AUTH_ACCOUNT_SETTINGS_TITLE: 'Ändra e-post',
    AUTH_ACCOUNT_CHANGE_PASSWORD: 'Ändra lösenord',
    AUTH_ACCOUNT_CHANGE_EMAIL: 'Ändra e-post',
    AUTH_SHIPPING_DETAILS: 'Leveransadress',
    AUTH_USER_DETAILS: 'Användaruppgifter',
    AUTH_USER_SETTINGS_PAGE_TITLE: 'Användarinställningar',
    GUEST_REGISTER_PAGE_TITLE: 'Faktureringsadress',
    MINUTES_SHORT: 'min.',
    CHECKOUT_PAGE_TITLE: 'Kassa',
    CHECKOUT_NO_ITEMS: 'Din varukorg är för närvarande tom',
    CHECKOUT_PRODUCT: 'Artikel',
    CHECKOUT_QUANTITY: 'Antal',
    CHECKOUT_VAT: 'Moms',
    CHECKOUT_PRICE: 'Pris',
    CHECKOUT_UNIT_PRICE: 'Styckpris',
    CHECKOUT_TYPE_GUIDED_TOUR: 'Guidning',
    CHECKOUT_TYPE_TICKET: 'Entrébiljett',
    CHECKOUT_TYPE_DAY_TICKET: 'Biljett',
    CHECKOUT_TOTAL: 'Totalt',
    CHECKOUT_DELETE_ARE_YOU_SURE: 'Är du säker?',
    CHECKOUT_DELETE_YES: 'Ja',
    CHECKOUT_DELETE_NO: 'Nej',
    CHECKOUT_BACK_TO_SHOPPING: 'Fortsätt handla',
    CHECKOUT_PAY_WITH: 'Betala med',
    CHECKOUT_CANCEL_ORDER: 'Avbryt',
    CHECKOUT_SUCCESS_PAGE_TITLE: 'Betalning lyckades',
    CHECKOUT_SUCCESS_MESSAGE: 'Tack för din beställning!',
    CHECKOUT_SUCCESS_TICKETS_IN_EMAIL:
      'Du kommer också att få dina biljetter via e-post (vid PayPal Express till den e-postadress som är registrerad hos PayPal)!',
    CHECKOUT_FAILED_MESSAGE: 'Betalning misslyckades',
    CHECKOUT_PAYMENT_ACCEPTED_PAGE_TITLE: 'Betalningssätt accepterat',
    CHECKOUT_CARD_ACCEPTED: 'Betalningsuppgifter bekräftade – vänligen slutför beställningen!',
    CHECKOUT_CONFIRM_ORDER: 'Beställning med betalningsskyldighet',
    CHECKOUT_NOT_LOGGEDIN_MESSAGE: 'Logga in innan du fortsätter med betalningen',
    CHECKOUT_PAYPAL_EXPRESS_NOT_LOGGEDIN_MESSAGE: 'För andra betalningsmetoder logga in',
    CHECKOUT_I_ACCEPT: 'Jag accepterar',
    CHECKOUT_THE_TERMS: 'AGB',
    CHECKOUT_THE_PRIVACY_POLICY: 'Sekretesspolicy',
    CHECKOUT_READ: 'Läsa',
    CHECKOUT_PAYMENT_CANCELED_PAGE_TITLE: 'Beställning avbruten',
    CHECKOUT_PAYMENT_CANCELED_MESSAGE: 'Din betalning avbröts. Vill du tömma varukorgen?',
    CHECKOUT_CLEAR_CART: 'Töm varukorg',
    CHECKOUT_KEEP_CART: 'Tillbaka till varukorgen',
    CHECKOUT_CONFIRM_ORDER_PAGE_TITLE: 'Granska och skicka beställning',
    CHECKOUT_AS_GUEST_MESSAGE: 'Du kan också boka som gäst',
    CHECKOUT_PAYPAL_EXPRESS_AS_GUEST_MESSAGE: 'eller checka ut som gäst',
    CHECKOUT_AS_GUEST_BUTTON: 'Boka som gäst',
    CHECKOUT_SHIPPING_COSTS: 'Fraktkostnader',
    CHECKOUT_SEND_INVOICE: 'Faktura önskas',
    CHECKOUT_SEND_INVOICE_INFORMATION: 'Faktureringsadress',
    CHECKOUT_PAYPAL_EXPRESS_TITLE_SUFFIX: 'Express (övertag data)',
    CHECKOUT_PAYPAL_EXPRESS_INFO: 'Använd dina paypal-uppgifter',
    CHECKOUT_CART_GONE:
      'Din varukorg är inte längre tillgänglig. Antingen är din beställning redan slutförd eller processen tog för lång tid. Kontrollera dina e-postmeddelanden för en bokningsbekräftelse eller försök igen senare.',
    CHECKOUT_NOT_PAYABLE: 'Varukorgen kan inte slutföras!',
    CHECKOUT_NO_PAY: 'Fortsätta',
    SHOPPING_CART: 'Varukorg',
    DIRECT_DEBIT: 'Direktdebitering',
    CHECKOUT_CANCEL: 'Annullera',
    CHECKOUT_NO_SHIPPING_COSTS_FOR_COUNTRY:
      'Inga portokostnader för detta land. Vänligen kontakta oss.',
    CHECKOUT_COMPLETE_ORDER: 'Slutför beställning',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_TEXT: 'Här hittar du detaljer om din',
    CHECKOUT_RIGHT_OF_WITHDRAWAL_LINK: 'Ångerrätt',
    NAV_VOUCHERS: 'Presentkort',
    VOUCHER_PAGE_TITLE: 'Presentkort',
    VOUCHER_CATEGORY_PAGE_TITLE: 'Presentkort',
    VOUCHER_TOTAL: 'Totalt',
    VOUCHER_ADDED_TO_CART: 'Artikel har lagts till i varukorgen',
    VOUCHER_UNIT_PRICE: 'per styck',
    VOUCHER_QUANTITY: 'Antal',
    VOUCHER_BUY: 'Köp',
    VOUCHER_OUT_OF_STOCK: 'Artikel ej tillgänglig',
    VOUCHER_EXPIRED: 'Artikeln är inte längre tillgänglig',
    VOUCHER_BUY_SUFFIX_SINGULAR: 'Köp presentkort',
    VOUCHER_BUY_SUFFIX_PLURAL: 'Köp presentkort',
    VOUCHER: 'Presentkort',
    VOUCHER_CODE: 'Presentkortskod',
    VOUCHER_GET_BUTTON: 'Lös in',
    VOUCHER_REMAINING_AMOUNT: 'Restvärde',
    VOUCHER_SELECTABLE_PRICE: 'Pris valfritt',
    VOUCHER_VALUE: 'Ange värde',
    SHIPPING_TITLE: 'Leveransadress',
    SHIPPING_CHANGE: 'Ändra',
    SHIPPING_SAME_AS_BILLING: 'Samma leveransadress',
    SHIPPING_FILL_ALL_FIELDS: 'Vänligen fyll i alla fält för leveransadress',
    SHIPPING_WEIGTH_FROM: 'Vikt från',
    BILLING_TITLE: 'Faktureringsadress',
    BILLING_CHANGE: 'Ändra',
    BILLING_SHIPPING_SAVE: 'Spara',
    MERCHANDISE_PAGE_TITLE: 'Butik',
    MERCHANDISE_CATEGORY_PAGE_TITLE: 'Butik',
    MERCHANDISE_TOTAL: 'Totalt',
    MERCHANDISE_UNIT_PRICE: 'per styck',
    MERCHANDISE_ADDED_TO_CART: 'Artikel har lagts till i varukorgen',
    MERCHANDISE_QUANTITY: 'Antal',
    MERCHANDISE_BUY: 'Köp',
    MERCHANDISE_BUY_SUFFIX_SINGULAR: 'Köp artikel',
    MERCHANDISE_BUY_SUFFIX_PLURAL: 'Köp artikel',
    MERCHANDISE_OUT_OF_STOCK: 'Artikel ej tillgänglig',
    MERCHANDISE_SOLDOUT: 'slutsåld',
    ANNUAL_TICKETS_CARD: 'Karta',
    ANNUAL_TICKETS_NAME: 'Titel, Förnamn, Efternamn',
    ANNUAL_TICKETS_SENT_TO_CUSTOM_ADDRESS: 'Skicka till anpassad adress',
    ANNUAL_TICKETS_ADDRESS: 'adress',
    USER_SETTINGS_SAVED: 'Inställningar sparade',
    USER_LOGIN_PAGE_TITLE: 'Logga in',
    USER_REGISTER_PAGE_TITLE: 'Registrera dig',
    USER_FORGOT_PAGE_TITLE: 'Glömda inloggningsuppgifter',
    USER_RECOVER_PAGE_TITLE: 'Återställ konto',
    USER_FORGOT_SUCCESS_PAGE_TITLE: 'Lösenordsåterställning lyckades',
    PRODUCT_PAGE_TITLE: 'Artikel',
    PAGE_NOT_FOUND_TITLE: 'Sidan kunde inte hittas',
    PAGE_NOT_FOUND_MESSAGE: 'Sidan kunde inte hittas',
    USER_REGISTER_SUCCESS_PAGE_TITLE: 'Inloggning lyckades',
    REGISTRATION_SUCCESS_TITLE: 'Inloggning accepterad',
    REGISTRATION_SUCCESS_TEXT: 'Ditt konto har skapats',
    FORGOT_PASSWORD_PAGE_TITLE: 'Glömt lösenord',
    RECOVER_PAGE_TITLE: 'Återställ konto',
    FOOTER_IMPRINT_LINK: 'Avtryck',
    FOOTER_TERMS_LINK_GTC: 'AGB',
    FOOTER_TERMS_LINK_DATA_PROTECTION: 'Sekretesspolicy',
    FOOTER_SHIPPING: 'Fraktkostnader',
    INTERNAL_SERVER_ERROR_MESSAGE_PREFIX:
      'Ett oväntat fel uppstod. För ytterligare support, vänligen kontakta oss via',
    INTERNAL_SERVER_ERROR_MESSAGE_SUFFIX: '.',
    PAYMENT_METHOD_CHOICE: 'Vänligen välj',
    REQUIRED_FIELDS: 'Obligatoriska uppgifter',
    SALUTATION_PLACEHOLDER: 'Vänligen välj',
    PROGRESS_BAR_CART: 'varukorg',
    PROGRESS_BAR_LOGIN: 'logga in',
    PROGRESS_BAR_PAYMENT: 'betalning',
    PROGRESS_BAR_COMPLETE: 'slutför',
    RESET_CATEGORY: 'återställ',
    SELECT_INPUT_PLACEHOLDER: 'vänligen välj...',
    COOKIE_ACCEPT: 'Jag samtycker!',
    EXTERNAL_DOCUMENT_NUMBER: 'Externt dokumentnummer',
    EXTERNAL_NUMBER_CONFIRM: 'Bekräfta',
    EXTERNAL_NUMBER_CHANGE: 'Ändra',
  },
};

if (typeof customTranslations !== 'undefined') {
  customTranslations().then((r) => {
    r.default(translations);
  });
}

export default translations;
